import { Component } from '@angular/core';
import { NbToastrService, NbWindowRef } from '@nebular/theme';
import { UserService } from '../../services/user.service';
import { showError, showSuccess } from '../../helpers/nb';

@Component({
  selector: 'app-new-vps-modal',
  templateUrl: './trigger-wix-compare-modal.component.html',
  styleUrls: ['./trigger-wix-compare-modal.component.scss']
})
export class TriggerWixCompareModalComponent {
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  option: string = 'byEmail';
  email: string = '';

  constructor(
    private windowRef: NbWindowRef,
    private userService: UserService,
    private toastrService: NbToastrService
  ) {
  }

  closeModal() {
    this.windowRef.close();
  }

  submitForm() {
    if (this.option === 'byEmail') {
      this.userService.validateUserByWix(this.email.trim())
        .then(({data}: any) => showSuccess(this.toastrService, data))
        .catch(({response}: any) => showError(this.toastrService, response.data))
        .finally(() => this.closeModal());
    } else {
      this.userService.validateAllUserByWix()
        .then(({data}: any) => showSuccess(this.toastrService, data))
        .catch(({response}: any) => showError(this.toastrService, response.data))
        .finally(() => this.closeModal());
    }
  }

  isEmailInputDisabledDisabled() {
    return this.option !== 'byEmail';
  }

  isButtonDisabled() {
    return this.isEmailInputDisabledDisabled()
      ? false
      : (this.email === undefined || this.email.trim() === '' || !this.isValidEmail(this.email))
  }

  isValidEmail(email: string): boolean {
    return this.emailPattern.test(email);
  }
}
