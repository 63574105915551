import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-stream-card-placeholder',
  templateUrl: './stream-card-placeholder.component.html',
  styleUrls: ['./stream-card-placeholder.component.scss']
})
export class StreamCardPlaceholderComponent {
  @Input() allowAddStream?: boolean;
  @Input() openNewStreamModal: () => void;

  onCardClick() {
    if (this.allowAddStream) {
      this.openNewStreamModal();
    } else {
      document.location.href=environment.homePage + "/membership";
    }
  }
}
