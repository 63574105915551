<div class="stream-card-placeholder" (click)="onCardClick()">
  <div>
      <div *ngIf="allowAddStream" class="stream-card-placeholder-content">
        <div >Add Stream</div>
        <nb-icon icon="plus-circle-outline" class="stream-card-placeholder-content-icon"/>
      </div>
      <div *ngIf="!allowAddStream" class="stream-card-placeholder-content">
        <div>Upgrade Your Plan to Add More Streams.</div>
        <nb-icon icon="arrow-circle-up-outline" class="stream-card-placeholder-content-icon"/>
      </div>
  </div>
</div>
