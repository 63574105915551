import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbToastrService,
  NbWindowRef,
  NbWindowService
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { DataState } from 'enum/data-state.enum';
import {
  BehaviorSubject,
  forkJoin, map,
  Observable, pairwise, startWith,
  Subscription, take
} from 'rxjs';
import { VideoPageActions } from 'store/actions/videos.actions';
import { Stream } from 'models/stream';
import { StreamStatus } from 'enum/streams.enum';
import { PlaylistVideo } from 'models/playlist';
import { User, Character, DraftCharacter, AiModel } from '../../models';
import { Router } from '@angular/router';
import { userFeature } from 'store/reducers/user.reducers';
import { showWarning } from 'helpers/nb';
import { buttonsConfig } from 'models/nb';
import { CharacterActions } from '../../store/actions/characters.actions';
import { characterFeature } from '../../store/reducers/characters.reducers';
import { NewCharacterModalComponent } from '../../components/new-character-modal/new-character-modal.component';
import { CharacterService } from '../../services/character.service';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import { ChatModalComponent } from '../../components/chat-modal/chat-modal.component';
import { AiModelService } from '../../services/ai-model.service';
import { StreamService } from '../../services/stream.service';
import { AxiosResponse } from 'axios';
import { StreamActions } from '../../store/actions/stream.actions';
import { streamFeature } from '../../store/reducers/stream.reducers';

@Component({
  selector: 'app-character',
  templateUrl: './character.component.html',
  styleUrls: ['./character.component.scss']
})
export class CharacterComponent implements OnInit, OnDestroy {
  readonly pageTitle = 'My Characters';
  readonly DataState = DataState;
  error$: Observable<string | null>;
  pageState$: Observable<DataState>;
  characters$:  Observable<Character[]>;
  countOfUploadingVideos: number = 0;
  user: User;
  userSelectSubscription: Subscription;
  characterModalRef: NbWindowRef<any, any>;
  selectedRows: Set<string> = new Set();
  aiModels: AiModel[] = [];
  streams$: Observable<Stream[]>;
  chatBotUsageBlocked$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private windowService: NbWindowService,
    private store: Store,
    private characterService: CharacterService,
    private aiModelService: AiModelService,
    private toastrService: NbToastrService,
    private streamService: StreamService,
    private router: Router,
  ) {
    this.pageState$ = this.store.pipe(select(characterFeature.selectPageState));
    this.characters$ = this.store.pipe(select(characterFeature.getAllCharacters));
    this.error$ = this.store.pipe(select(characterFeature.getCharactersPageError));
    this.streams$ = this.store.pipe(select(streamFeature.selectStreamState));
  }

  ngOnInit(): void {
    this.store.dispatch(VideoPageActions.getVideos());
    this.aiModelService.getAll()
      .then(({data}) => {
        this.aiModels = data;
      })
      .catch(err => {
        showWarning(this.toastrService, "failed to load models")
        console.error(err);
      })
    this.userSelectSubscription = this.store
      .pipe(
        select(userFeature.selectUserState),
        startWith(null),
        pairwise(),
      )
      .subscribe(([prevUser, user]: any) => {
        if (prevUser == null) {
          this.user = user;
          const supportModeEnabled = sessionStorage.getItem('supportMode');
          if (this.user && !this.user.isVpsAssigned && this.user.isAdmin && !supportModeEnabled) {
            this.router.navigate(['/dashboard']);
          } else {
            this.store.dispatch(CharacterActions.getCharactersList({ userUuid: this.user.uuid }));
          }
        }

        if (user) {
          if (!!user?.assignedVps?.chatBotUsageBlocked != this.chatBotUsageBlocked$.value) {
            this.user = user;
            this.chatBotUsageBlocked$.next(!!this.user?.assignedVps?.chatBotUsageBlocked);

            if (this.chatBotUsageBlocked$.value) {
              this.showWarningRegardingChatbotUsage(this.chatBotUsageBlocked$.value);
            }
          }
        }
      })

    this.streamService.fetchStreams().then(({data}: AxiosResponse<Stream[]>) => {
      this.store.dispatch(StreamActions.getStreams({streams: data}))
    })
  }

  validateCharacterUsage$(character: Character) {
    return this.streams$.pipe(take(1)).pipe(
      map(allStreams => this.isCharacterUsedByStreams(character, allStreams))
    )
  }

  isCharacterUsedByStreams(character: Character, allStreams: Stream[]) {
    const usedCharacters = this.getUsedCharactersSet(allStreams);
    return usedCharacters.has(character.id);
  }

  isAnyOfCharactersUsedByStreams(charactersIds: string[], allStreams: Stream[]) {
    const usedCharacters = this.getUsedCharactersSet(allStreams);
    return charactersIds.some((id: string) => usedCharacters.has(id));
  }

  getUsedCharactersSet(allStreams: Stream[]) {
    return new Set(allStreams
      .filter(stream => stream.status === StreamStatus.Online && stream.character)
      .map((activeStream: Stream) => activeStream?.character?.id)
    )
  }

  deleteCharacters() {
    const charactersIds = [...this.selectedRows];
    this.streams$.pipe(take(1)).subscribe(allStreams => {
      const characterIsUsed = this.isAnyOfCharactersUsedByStreams(charactersIds, allStreams)
      if (characterIsUsed) {
        showWarning(this.toastrService, 'To delete this characters, you must first stop the stream that is currently using them. The characters cannot be removed while they are still being utilized by the stream.')
        return;
      }

      this.windowService.open(ConfirmModalComponent, {
        title: `Are You Sure You Want to Delete these Characters?`,
        buttons: buttonsConfig,
        context: {
          textContent: `This action will permanently delete the selected characters and it cannot be undone.`,
          actionCallback: () => this.store.dispatch(CharacterActions.deleteCharacters({ids: charactersIds})),
        },
      });
    })
  }

  onCreateModalSubmit(character: DraftCharacter) {
    this.characterService.createNewCharacter(character).then(({data}) => {
      this.store.dispatch(CharacterActions.addCharacter({ character: data }));
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      this.characterModalRef.close();
    })
  }
  onEditModalSubmit(character: Character) {
    this.characterService.updateCharacter(character).then(({data}) => {
      this.store.dispatch(CharacterActions.updateCharacter({ character: data }));
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      this.characterModalRef.close();
    })
  }


  openNewCharacterModal() {
    forkJoin([
      this.characters$.pipe(take(1)),
    ]).subscribe(([characters]) => {
      this.characterModalRef = this.windowService.open(NewCharacterModalComponent, {
        title: `Create new character`,
        buttons: buttonsConfig,
        closeOnEsc: false,
        context: {
          user: this.user,
          onSubmit: this.onCreateModalSubmit.bind(this),
          onError: showWarning.bind(this, this.toastrService),
          aiModels: this.aiModels,
        },
      });
    });
  }

  openCharacterEditModal(character: Character): void {
    this.characterModalRef = this.windowService.open(NewCharacterModalComponent, {
      title: `Edit character`,
      buttons: buttonsConfig,
      closeOnEsc: false,
      context: {
        user: this.user,
        character: character,
        onSubmit: this.onEditModalSubmit.bind(this),
        onError: showWarning.bind(this, this.toastrService),
        aiModels: this.aiModels,
      },
    });
  }

  openCharacterTestModal(character: Character): void {
    this.streams$.pipe(take(1)).subscribe(allStreams => {
      this.characterModalRef = this.windowService.open(ChatModalComponent, {
        title: `Test character chat`,
        buttons: buttonsConfig,
        closeOnEsc: false,
        context: {
          user: this.user,
          character: character,
          onSubmit: () => {},
          scrollToBottom: this.scrollToBottom.bind(this),
          onError: showWarning.bind(this, this.toastrService),
          chatBotUsageBlocked$: this.chatBotUsageBlocked$,
          aiModels: this.aiModels,
          streams: allStreams
        },
      });
      this.scrollToBottom()
    })
  }

  scrollToBottom(): void {
    if (this.characterModalRef) {
      const nativeElement = this.characterModalRef.componentRef.location.nativeElement.children[0].children[1];
      nativeElement.scrollTop = nativeElement.scrollHeight;
    }
  }

  ngOnDestroy() {
    this.userSelectSubscription.unsubscribe();
  }

  private isVideosUsedByStreams(videosIds: number[], allStreams: Stream[]) {
    const playlistVideos: PlaylistVideo[] = allStreams
      .filter(stream => stream.status === StreamStatus.Online)
      .map((activeStream: Stream) => activeStream.playlist?.playlistVideos || [])
      .flat();

    return playlistVideos.some(item => videosIds.some(videoId => item.video.id === videoId));
  }

  isDeleteButtonDisabled() {
    return !this.selectedRows.size;
  }

  changeSelectedRows(selectedRows: Set<string>) {
    this.selectedRows = selectedRows;
  }

  showWarningRegardingChatbotUsage(shouldShowMessage: boolean) {
    if (shouldShowMessage) {
      showWarning(this.toastrService, 'Your monthly token allowance has been used up. The chatbot will no longer respond until your next billing cycle begins')
    }
  }
}
