import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ResourceResponse, ResourceEntry, ResourceUploadPayload, PollingPayload } from 'models/resource';
import { UploadVideoSource } from '../../enum/upload-source.enum';
import { AxiosError } from 'axios';

export const VideoPageActions = createActionGroup({
  source: 'Video Page',
  events: {
    'Get Videos': emptyProps(),
    'Get Videos success': props<{ payload: ResourceResponse<ResourceEntry> }>(),
    'Get Videos failure': props<{ error: string }>(),
    'Add Video': props<{ payload: ResourceEntry }>(),
    'Update Video': props<{ payload: ResourceEntry }>(),
    'Remove Video': props<{ id: number }>(),
    'Remove Video success': props<{ id: number }>(),
    'Remove Video failure': props<{ id: number }>(),
    'Remove Videos': props<{ ids: number[] }>(),
  }
});

export const VideoUploadActions = createActionGroup({
  source: 'Video Upload',
  events: {
    'Upload Progress Start': props<{ id: number }>(),
    'Upload Progress Update': props<{ payload: PollingPayload }>(),
    'Upload Progress Complete': props<{ payload: ResourceEntry }>(),
    'Upload Progress Failure': props<{ id: number }>(),
    'Upload Resource To Big': props<{ id: number }>(),
    'Upload Local Video': props<{ payload: ResourceUploadPayload }>(),
    'Upload Url Video': props<{ payload: ResourceUploadPayload, source: UploadVideoSource }>(),
    'Start Polling': emptyProps(),
    'Stop Polling': emptyProps(),
    'Authorization Required': props<{
      authCode: string | null| boolean,
      error?: string | AxiosError,
      status?: number
    }>(),
    'Upload Url Video Failed': props<{ error?: any, id: number }>(),
    'Check Service Access': props<{ platformName: string }>(),
    'Update Access Required Videos': emptyProps(),
    'Update Access Required Videos Started': emptyProps(),
  }
});
