import { Component, Input, OnInit } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Character, ChatMessage, Stream } from '../../models';
import { select, Store } from '@ngrx/store';
import { CharacterChatActions } from '../../store/actions/characters.actions';
import { Observable, Subscription } from 'rxjs';
import { characterFeature } from '../../store/reducers/characters.reducers';

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss']
})
export class ChatModalComponent implements OnInit {
  @Input('character') character: Character;
  @Input('stream') stream: Stream | undefined;
  @Input('scrollToBottom') scrollToBottom: () => void;
  username: string;
  chatForm: FormGroup;
  isLoading: boolean = false;
  messages$: Observable<ChatMessage[]>;
  tokens$: Observable<Number>;
  subscription: Subscription;

  constructor(
    private windowRef: NbWindowRef,
    private fb: FormBuilder,
    private store: Store,
  ) {
    this.chatForm = this.fb.group({
      message: ['', Validators.required]
    });
    this.messages$ = this.store.pipe(select(characterFeature.getChatMessages));
    this.tokens$ = this.store.pipe(select(characterFeature.getTokens));
  }

  ngOnInit(): void {
    if (this.character.firstMes) {
      this.store.dispatch(CharacterChatActions.addMessage({
        message: {
          name: this.character.name.replace(/ /g, ""),
          content: this.character.firstMes,
          role: "assistant",
        },
        tokens: 0
      }));
    }

    this.subscription = this.messages$.subscribe(messages => {
      setTimeout(() => this.scrollToBottom(), 100);
    })
  }
  ngOnDestroy() {
    this.store.dispatch(CharacterChatActions.clearMessages());
    this.subscription.unsubscribe();
  }

  sendMessage(): void {
    if (this.chatForm.valid) {
      this.store.dispatch(CharacterChatActions.sendMessage({
        message: {
          content: this.chatForm.value.message,
          name: this.username ? this.username.replace(/ /g, "") : this.username,
          role: "user",
        },
        character: this.character,
        stream: this.stream
      }))
      this.chatForm.reset();
    }
  }

  closeModal(): void {
    this.windowRef.close();
  }
}
