export enum StreamStatus {
  Offline,
  Online,
  Stopped,
  Error,
  Ended
}

export enum StreamPlatform {
  Custom,
  YouTube,
  Twitch,
  Facebook,
  Kick,
  Rumble
}

export enum PlatformStreamUrl {
  "",
  "rtmp://a.rtmp.youtube.com/live2",
  "rtmp://dus01.contribute.live-video.net/app",
  "rtmp://127.0.0.1:1936/rtmp",
  "rtmp://127.0.0.1:1937/rtmp",
  "rtmp://rtmp.rumble.com/live"
}
