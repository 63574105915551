import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageChange } from 'models/events';

@Component({
  selector: 'app-card-pagination',
  templateUrl: './card-pagination.component.html',
  styleUrls: ['./card-pagination.component.scss']
})
export class CardPaginationComponent implements OnInit {
  @Output() onPageChange = new EventEmitter<PageChange>();
  @Input('collectionSize') totalItems: number = 0;
  @Input('LSKey') LSKey: string;
  @Input('currentPage') currentPage: number = 1;

  pageSize: number;

  ngOnInit(): void {
    let pageSizeFromStore = localStorage.getItem(this.LSKey) ?? "10";
    this.pageSize = parseInt(pageSizeFromStore);
  }

  getMaxPages() {
    return Math.ceil(this.totalItems / this.pageSize) || 1;
  }

  next() {
    this.onPageChange.emit({
      currentPage: ++this.currentPage,
      pageSize: this.pageSize,
    });
  }

  previous() {
    this.onPageChange.emit({
      currentPage: --this.currentPage,
      pageSize: this.pageSize,
    });
  }

  changeSize() {
    localStorage.setItem(this.LSKey, this.pageSize.toString());
    this.currentPage = 1;
    this.onPageChange.emit({
      currentPage: this.currentPage,
      pageSize: this.pageSize,
    });
  }
}
