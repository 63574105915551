<div class="square-container"
  [style.width.px]="size"
  [style.height.px]="size"
  [style.min-width.px]="size"
  [style.min-height.px]="size"
>
  <img
    *ngIf="src"
    [src]="src"
    class="square-container-image"
    [ngClass]="{'rotate': !isVertical}"
    [ngbTooltip]="tooltip"
  />
  <div *ngIf="icon">
    <nb-icon
      [icon]="icon"
      class="square-container-image square-container-image-icon"
      [ngClass]="{'rotate': isVertical}"
      [ngbTooltip]="tooltip"
      status="control"
    />
  </div>
</div>
