export enum UploadVideoSource {
  LOCAL = 'local',
  YOUTUBE = 'youtube',
  TWITCH = 'twitch',
  YOUTUBE_PLAYLIST = 'youtube_playlist'
}

export enum VideoSource {
  YOUTUBE = 'youtube',
  TWITCH = 'twitch',
}

export enum VideoSourceToShow {
  YOUTUBE = 'YouTube',
  TWITCH = 'Twitch',
}
