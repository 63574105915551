<app-top-bar [page-title]="pageTitle"/>
<ng-container *ngIf="(pageState$ | async) as pageState" [ngSwitch]="pageState">
  <ng-container *ngSwitchCase="DataState.LOADING">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="DataState.LOADED">

    <app-card>
      <button nbButton (click)="triggerWixCompare()">Trigger Wix Compare</button>
    </app-card>
  </ng-container>
  <!-- ERROR -->
  <ng-container *ngSwitchCase="DataState.ERROR">
    <div class="d-flex flex-column my-characters-error">
      <img class="my-characters-error-img" src="assets/sadcloud.png" alt="No characters">
      <p class="my-characters-error-text">Failed</p>
    </div>
  </ng-container>

</ng-container>
