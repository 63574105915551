<div class="chat-modal">
  <div class="message-list">
    <nb-chat [scrollBottom]="true" class="chat-modal-chat-container">
      <nb-chat-message
        *ngFor="let chatMessage of (messages$ | async)"
        [message]="chatMessage.content"
        [sender]="chatMessage.name"
      />
    </nb-chat>
  </div>

  <div class="chat-modal-separator"></div>

  <div class="chat-modal-bottom">
    <div class="user-chat">
      <div class="user-chat-top">
        <div>
          <label for="user-name">User Name</label>
          <input
            id="user-name"
            nbInput
            placeholder="type user name"
            type="text"
            maxlength="64"
            [(ngModel)]="username"
          >
        </div>
        <div>
          Tokens: {{ tokens$ | async }}
        </div>
      </div>
      <div>
        <form [formGroup]="chatForm" (ngSubmit)="sendMessage()" class="chat-modal-form">
          <div class="form-group chat-modal-form-textarea">
            <label for="form-group">Message</label>
            <textarea
              id="form-group"
              placeholder="Type your message"
              formControlName="message"
              class="form-control"
              rows="3">
          </textarea>
          </div>
          <div class="actions">
            <button
              nbButton
              [disabled]="chatForm.invalid || isLoading || !username"
              status="primary"
              class="chat-modal-form-send-button"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
    <button nbButton status="danger" class="chat-modal-close" (click)="closeModal()">Close</button>
  </div>
</div>

