import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-oriental-rectangle',
  templateUrl: './oriental-rectangle.component.html',
  styleUrls: ['./oriental-rectangle.component.scss']
})
export class OrientalRectangleComponent {
  @Input() isVertical: boolean = false;
  @Input() size: number = 200;

  prepareTooltip() {
    if (this.isVertical) {
      return "Vertical video stream"
    }
    return "Horizontal video stream"
  }
}
