import { Component } from '@angular/core';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { Stream, StreamCreation } from '../../models/stream';
import { Playlist } from '../../models/playlist';
import { select, Store } from '@ngrx/store';
import { StreamService } from '../../services/stream.service';
import { StreamActions } from '../../store/actions/stream.actions';
import { PlatformStreamUrl, StreamPlatform } from 'enum/streams.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/environment';
import { allowedStreamPlatforms } from '../../consts';
import { StreamCharacterModalComponent } from '../stream-character-modal/stream-character-modal.component';
import { buttonsConfig, Character, User } from '../../models';
import { showWarning } from '../../helpers/nb';
import { Observable, take } from 'rxjs';
import { characterFeature } from '../../store/reducers/characters.reducers';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-new-stream-modal',
  templateUrl: './new-stream-modal.component.html',
  styleUrls: ['./new-stream-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: '0',
        width: '0'
      })),
      transition('void <=> *', animate('500ms ease-in-out'))
    ])
  ]
})
export class NewStreamModalComponent {

  // Modal props
  editableStream: StreamCreation | Stream;
  playlists: Playlist[] = [];
  streams: Stream[] = [];
  onError: (message: string) => void;
  testCharacter: (stream: any) => void;
  viewOnly = false;
  user: User;
  characters$: Observable<Character[]>;

  showStreamingKey: boolean = false;
  readonly StreamPlatform = StreamPlatform;

  constructor(
    private windowRef: NbWindowRef,
    private streamService: StreamService,
    private store: Store,
    private windowService: NbWindowService,
    private toastrService: NbToastrService,
  ) {
    this.characters$ = this.store.pipe(select(characterFeature.getAllCharacters));
  }

  updateField({value}: any, fieldName: string) {
    this.editableStream = {...this.editableStream, [fieldName]: value}
  }

  submit() {
    this.editableStream = {
      ...this.editableStream,
      playlist: this.playlists.find((pl) => pl.id == this.editableStream?.playlistId)
    }
    const currentStreamId = this.editableStream.id;
    let isDuplicatedKey = false;

    if (currentStreamId) {
      isDuplicatedKey = this.streams
        .filter(stream => (stream.id !== currentStreamId && stream.streamKey))
        .some(stream => stream.streamKey === this.editableStream.streamKey);
    } else {
      isDuplicatedKey= this.streams
        .filter(stream => stream.streamKey)
        .some(stream => stream.streamKey === this.editableStream.streamKey);
    }

    if (isDuplicatedKey) {
      this.onError("This streaming key already exist!");
      return;
    }

    this.streamService.createOrUpdateStream(this.editableStream).then(({data}) => {
      if (currentStreamId) {
        this.store.dispatch(StreamActions.updateStream({stream: data}))
      } else {
        this.store.dispatch(StreamActions.addStream({stream: data}))
      }
    })
    this.windowRef.close();
  }

  onPlatformChange(value: number) {
    const streamingUrl = PlatformStreamUrl[value]
    this.updateField({value: streamingUrl}, "streamUrl")
  }

  closeModal() {
    this.windowRef.close();
  }

  selectComparator(v1: any, v2: any) {
    return v1 == v2
  }

  isVertical(playlist: Playlist) {
    if (!!playlist?.playlistVideos?.length
      && !playlist.playlistVideos.some(({video}) => !video.isVerticalOrientation)
    ) {
      return " - VERTICAL";
    } else {
      return "";
    }
  }

  editCharacter() {
    this.windowService.open(StreamCharacterModalComponent, {
      title: `${false ? 'Edit' : 'Assign'} video character`,
      buttons: buttonsConfig,
      closeOnEsc: false,
      context: {
        user: this.user,
        stream: this.editableStream,
        onSubmit: this.onCharacterModalSubmit.bind(this),
        onError: showWarning.bind(this, this.toastrService),
      },
      windowClass: "scroll-modal"
    });
  }

  onCharacterModalSubmit(data: any) {
    const { scenario, characterId } = data;
    this.characters$.pipe(take(1)).subscribe((characters) => {
      let character = characters.find(character => character.id === characterId)
      this.editableStream = { ...this.editableStream, scenario, character };
    })
  }

  unassignCharacter() {
    this.editableStream = { ...this.editableStream, scenario: undefined, character: undefined };
  }

  getCharacterName() {
    return this.editableStream?.character?.name || '';
  }

  isUserInAllowedToChatbot() {
    return this.user?.subscriptionPlan?.chatbot;
  }

  protected readonly environment = environment;
  protected readonly allowedStreamPlatforms = allowedStreamPlatforms;
}
