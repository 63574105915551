import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { resourceFeature } from 'store/reducers/videos.reducers';
import { UploadStatus } from 'enum/upload-status.enum';
import { getStatusFromProgress } from 'helpers/videoStatus.helper'
import { VideoStatusCode } from '../../enum/video-status.code';


@Component({
  selector: 'app-video-status',
  templateUrl: './video-status.component.html',
  styleUrls: ['./video-status.component.scss'],
})
export class VideoStatusComponent implements OnInit, OnDestroy {

  @Input('id') videoId: number;
  @Output() openCheckAccessModal = new EventEmitter<any>();

  progress: number;
  status: UploadStatus;
  status_code: VideoStatusCode;
  subscription: Subscription;
  readonly UploadStatus = UploadStatus;

  getStatusFromProgress = getStatusFromProgress;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.subscription = this.store.pipe(
      select(resourceFeature.getResourceUploadProgress({ id: this.videoId }))
    ).subscribe((value) => this.progress = value);
    this.subscription = this.store.pipe(
      select(resourceFeature.getResourceStatusCode({ id: this.videoId }))
    ).subscribe((value) => this.status_code = value);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  checkAccess() {
    this.openCheckAccessModal.emit();
  }

  getVideoStatusCode() {
    if (UploadStatus.PROCESSING === getStatusFromProgress(this.progress) || this.status_code === VideoStatusCode.PENDING_UPLOADING || this.status_code === VideoStatusCode.PREPARING) {
      return 1;
    } else if (UploadStatus.UPLOADING === getStatusFromProgress(this.progress) || UploadStatus.UPLOADING === getStatusFromProgress(this.progress)) {
      return 2;
    } else if (UploadStatus.READY === getStatusFromProgress(this.progress) && this.status_code === VideoStatusCode.READY_FOR_USE) {
      return 3;
    } else if (UploadStatus.READY === getStatusFromProgress(this.progress) && this.status_code === VideoStatusCode.ERROR_CODEC) {
      return 4;
    } else if (UploadStatus.ERROR === getStatusFromProgress(this.progress) && this.status_code === VideoStatusCode.ERROR_ACCESS) {
      return 6;
    } else if (UploadStatus.ERROR === getStatusFromProgress(this.progress) && this.status_code === VideoStatusCode.ERROR_SIZE) {
      return 7;
    } else if (UploadStatus.ERROR === getStatusFromProgress(this.progress) || this.status_code === VideoStatusCode.ERROR_UPLOADING) {
      return 5;
    } else {
      return 0;
    }
  }
}
