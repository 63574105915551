<ng-container *ngIf="(streams$ | async) as streams">
  <app-top-bar
    [page-title]="pageTitle"
    [labels]="[
      [
        streams.length,
        'Stream' + (streams.length > 1 ? 's' : ''),
        user.subscriptionPlan && user.subscriptionPlan.availableStreams
        ? 'out of ' + user.subscriptionPlan.availableStreams
        : ''
      ]
    ]"
  />

  <ng-container *ngIf="streams.length">
    <div class="my-streams-container">
      <div class="action-header">
        <button
          nbButton
          *ngIf="streams.length < (user?.subscriptionPlan?.availableStreams || 0)"
          status="primary"
          class="header-button"
          (click)="openNewStreamModal()"
        >
          Add Stream
        </button>
      </div>

      <div class="page-content">
        <app-stream-card
          *ngFor="let stream of streams"
          [stream]="stream"
          [editCallback]="openEditStream.bind(this)"
          [openDetailsCallback]="openDetailsStream.bind(this)"
          [startCallback]="startStream.bind(this)"
          [stopCallback]="stopStream.bind(this)"
          [isChatBotAllowed]="isChatBotAllowed"
          [isChatBotBlockedByCosts]="chatBotUsageBlocked$.value"
          [isVpsUp]="user.assignedVps && user.assignedVps.isVpsUp || false"
        />
        <app-stream-card-placeholder
          *ngFor="let card of getStreamCardPlaceholders$ | async"
          [allowAddStream]="card && card.allowAddStream"
          [openNewStreamModal]="openNewStreamModal.bind(this)"
        />
      </div>


      <div class="upgrade-container d-flex justify-content-between" *ngIf="dialogActive && isUpgradeModalVisible()">
        <span class="upgrade-text d-flex align-items-center">
          Maximize your impact and reach today! Upgrade to our top-tier plan to push up to 4 simultaneous streams.
        </span>
        <div class="action-buttons d-flex align-items-center">
          <button
            nbButton
            status="primary"
            class="modal-button mx-2"
            size="giant"
            (click)="openUpgradePlan()"
          >
            Try Now
          </button>
          <i
            class="ph-light ph-x mx-2"
            style="font-weight: bold; font-size: 32px"
            (click)="temporaryCloseUpgradeDialog()"
          ></i>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!streams.length">
    <div class="d-flex justify-content-center align-items-center h-75">
      <div class="my-videos-action-header d-flex flex-column">
        <img src="assets/logo-wo-text.svg" style="width: 300px; height: 200px; padding-left: 45px;"/>
        ​<button
          nbButton
          status="primary"
          (click)="openNewStreamModal()"
          [ngStyle]="{
              'backgroundColor': 'var(--button-dark)',
              'border': 'none'
          }"
        >
          Create your first stream
        </button>
      </div>
    </div>
  </ng-container>

</ng-container>
