import { Component } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { getAuthorizationCode } from 'store/reducers/videos.reducers';
import { splitFileName } from 'helpers/resource.helper';
import { Clipboard } from '@angular/cdk/clipboard';
import { VideoUploadActions } from '../../store/actions/videos.actions';
import { UploadVideoSource } from '../../enum/upload-source.enum';

enum ValidationErrors {
  FILLING = "Please specify videos you want to upload",
  SPACE = "You do not have enough space",
  URLS = "Could not validate these links",
  URL = "Could not validate this link",
}

@Component({
  selector: 'app-new-video-modal',
  templateUrl: './new-video-modal.component.html',
  styleUrls: ['./new-video-modal.component.scss']
})
export class NewVideoModalComponent {
  youtubeUploadBlocked: boolean = !localStorage.getItem("youtube-upload"); // TODO: remove after fix youtube upload

  limitLocalFiles = 10;
  limitUrlLinks = 30;

  urls: string[] = [];
  urls_v: string = '';
  source: UploadVideoSource = UploadVideoSource.FROM_PC; // UploadVideoSource.YOUTUBE; // TODO: remove after fix youtube upload;
  local_files: File[] = [];
  validation_error: ValidationErrors;
  isLoading: boolean = false;
  authorizationCode$: Observable<string | null | boolean>;

  // Modal Props
  space_available: number;
  onError: (message: string) => void;
  onSubmit: (...args: any) => void;
  youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  twitchRegex = /^(?:https?:\/\/)?(?:www\.)?twitch\.tv\/videos\/(\d+)(?:\/)?$/;
  youtubePlaylistRegex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/playlist\?list=([a-zA-Z0-9_-]+)/;
  platform: number = 1;
  private subscription: Subscription;
  twitchPlaceholder: string = "https://www.twitch.tv/videos/21407111674\nhttps://www.twitch.tv/videos/51407511676";
  youtubePlaceholder: string = "https://www.youtube.com/watch?v=5lBW8ujeOjg\nhttps://www.youtube.com/watch?v=6lBW-ujrOjg";
  youtubePlaylistPlaceholder: string = "https://www.youtube.com/playlist?list=PL9R3ehQiNA2nvnDTTo4DTcH4lTaJ9AKon";
  constructor(private windowRef: NbWindowRef, private store: Store, private clipboard: Clipboard) {
    this.authorizationCode$ = this.store.select(getAuthorizationCode);
  }

  ngOnInit() {
    this.subscription = this.authorizationCode$.subscribe(authCode => {
      if (authCode || authCode === false) {
        this.isLoading = false;
      }
    })
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onUrlEnter(urls: string) {
    this.urls_v = urls;
    if (this.source !== UploadVideoSource.YOUTUBE_PLAYLIST) {
      this.urls = urls
        .split(/\r?\n/)
        .map(url => url.trim())
        .filter(url => url.length > 0);
    } else {
      this.urls = [urls];
    }


    if (this.urls.length > this.limitUrlLinks) {
      // this.urls = this.urls.slice(0, this.limitUrlLinks);
    }
  }

  onFilesDropped(filesArray: File[]) {
    this.local_files = this.sortFilesByIdentity(this.local_files.concat(filesArray));
    if (this.local_files.length >= this.limitLocalFiles) {
      this.local_files = this.local_files.slice(0, this.limitLocalFiles);
    }
  }

  onLocalFileSelect(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = (target.files as FileList);
    const filesArray = [].slice.call(files);
    this.local_files = this.sortFilesByIdentity(this.local_files.concat(filesArray));
    if (this.local_files.length >= this.limitLocalFiles) {
      this.local_files = this.local_files.slice(0, this.limitLocalFiles);
    }
  }

  submit() {
    if (!this.isFormSubmissionValid()) {
      this.onError(this.validation_error);
      return;
    }
    this.isLoading = true;
    this.onSubmit(...this.prepareDataForSubmit());
  }

  onTabChange(data: any) {
    if (data.tabTitle == "FROM YOUTUBE") {
      this.source = UploadVideoSource.YOUTUBE;
      this.platform = 1;
    } else if (data.tabTitle == "FROM TWITCH") {
      this.source = UploadVideoSource.TWITCH;
      this.platform = 2;
    } else if (data.tabTitle == "FROM YOUTUBE PLAYLIST") {
      this.source = UploadVideoSource.YOUTUBE_PLAYLIST;
      this.platform = 1;
    } else {
      this.source = UploadVideoSource.LOCAL;
    }
    this.resetSelection();
  }

  closeModal() {
    this.resetSelection();
    this.windowRef.close();
  }

  isRightFileFormat(file: File) {
    const [_, ext] = splitFileName(file);
    const requiredVideoContainers: string[] = ["mp4", "flv", "MP4", "FLV"];
    return requiredVideoContainers.includes(ext);
  }

  isFormSubmissionValid(): boolean {
    if (this.urls.length === 0 && this.local_files.length === 0) {
      this.validation_error = ValidationErrors.FILLING;
      return false;
    } else if (this.local_files.length > 0 && !this.hasFreeSpace()) {
      this.validation_error = ValidationErrors.SPACE;
      return false;
    } else if (this.source !== UploadVideoSource.LOCAL
      && this.urls
      && !this.urls.every(url => this.matchUrl(url, this.source))
    ) {
      this.validation_error = this.urls.length === 1 ? ValidationErrors.URL : ValidationErrors.URLS;
      return false;
    }
    return true;
  }

  deleteChosenFile(file: File) {
    this.local_files = this.local_files.filter(f => f.name !== file.name);
  }

  copyAuthCode(authCode: string): void {
    this.clipboard.copy(authCode);
    alert('Authorization code copied to clipboard!');
  }

  private prepareDataForSubmit() {
    if (this.local_files.length > 0) {
      return [UploadVideoSource.LOCAL, this.local_files.filter(this.isRightFileFormat)];
    } else if (this.urls.length > 0 && this.source !== UploadVideoSource.LOCAL) {
      return [
        this.source,
        this.urls
        .filter(Boolean)
        .filter(url => this.matchUrl(url, this.source))
        .map(url => this.matchUrl(url, this.source))
      ];
    } else {
      return [];
    }
  }

  private hasFreeSpace() {
    const totalSpaceOfSelectedFiles = this.local_files.reduce((acc, current) => acc += current.size, 0);
    return this.space_available > totalSpaceOfSelectedFiles;
  }

  private matchUrl(url: string, source: UploadVideoSource) {
    let regex = this.getRegexpBySource(source);
    let matches = url.match(regex);
    return matches ? matches[1] : false;
  }

  getRegexpBySource(source: UploadVideoSource) {
    switch (source) {
      case UploadVideoSource.TWITCH: {
        return this.twitchRegex;
      }
      case UploadVideoSource.YOUTUBE: {
        return this.youtubeRegex;
      }
      case UploadVideoSource.YOUTUBE_PLAYLIST: {
        return this.youtubePlaylistRegex;
      }
      default: {
        return this.youtubeRegex
      }
    }
  }

  private resetSelection() {
    this.urls = [];
    this.urls_v = '';
    this.local_files = [];
    this.store.dispatch(VideoUploadActions.authorizationRequired({ authCode: null }));
  }

  private sortFilesByIdentity(arrayToSort: File[]) {
    let unique = arrayToSort.sort((a, b) => a.name.localeCompare(b.name));
    let accumulator: File[] = [];

    unique = arrayToSort.reduce((acc, current) => {
      const lastIndex = acc.length - 1;
      if (lastIndex >= 0 && acc[lastIndex].name === current.name) {
        acc[lastIndex] = current;
      } else {
        acc.push(current);
      }
      return acc;
    }, accumulator);

    return unique;
  }
}
