import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbToastrService,
  NbWindowRef,
  NbWindowService
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { DataState } from 'enum/data-state.enum';
import {
  forkJoin,
  Observable,
  Subscription, take
} from 'rxjs';
import { VideoPageActions } from 'store/actions/videos.actions';
import { ResourceService } from 'services/resource.service';
import { Stream } from 'models/stream';
import { StreamStatus } from 'enum/streams.enum';
import { PlaylistVideo } from 'models/playlist';
import { User } from '../../models';
import { Router } from '@angular/router';
import { userFeature } from 'store/reducers/user.reducers';
import { showWarning } from 'helpers/nb';
import { buttonsConfig } from 'models/nb';
import { convertBytesToReadable } from '../../pipes/video-size.pipe';
import { CharacterActions } from '../../store/actions/characters.actions';
import { characterFeature } from '../../store/reducers/characters.reducers';
import { Character, DraftCharacter } from '../../models/character';
import { AiModel } from '../../models/ai-model';
import { NewCharacterModalComponent } from '../../components/new-character-modal/new-character-modal.component';
import { CharacterService } from '../../services/character.service';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import { ChatModalComponent } from '../../components/chat-modal/chat-modal.component';
import { AiModelService } from '../../services/ai-model.service';

@Component({
  selector: 'app-character',
  templateUrl: './character.component.html',
  styleUrls: ['./character.component.scss']
})
export class CharacterComponent implements OnInit, OnDestroy {

  readonly pageTitle = 'My Characters';
  readonly DataState = DataState;
  error$: Observable<string | null>;
  pageState$: Observable<DataState>;
  characters$:  Observable<Character[]>;
  charactersLength: number = 0;
  countOfUploadingVideos: number = 0;
  vpsStatistics: { usedSpace: number, count: number, length: number } = {
    length: 0,
    usedSpace: 0,
    count: 0
  };
  user: User;
  userSelectSubscription: Subscription;
  spaceAvailableByPlan: number = 0; // todo: Get value from state
  characterModalRef: NbWindowRef<any, any>;
  selectedRows: Set<string> = new Set();
  aiModels: AiModel[] = [];

  constructor(
    private windowService: NbWindowService,
    private store: Store,
    private resourceService: ResourceService,
    private characterService: CharacterService,
    private aiModelService: AiModelService,
    private toastrService: NbToastrService,
    private router: Router,
  ) {
    this.pageState$ = this.store.pipe(select(characterFeature.selectPageState));
    this.characters$ = this.store.pipe(select(characterFeature.getAllCharacters));
    this.error$ = this.store.pipe(select(characterFeature.getCharactersPageError));
  }

  ngOnInit(): void {
    this.store.dispatch(VideoPageActions.getVideos());
    this.characters$
      .pipe(take(1))
      .subscribe((characters) => this.charactersLength = characters?.length || 0)
    this.aiModelService.getAll()
      .then(({data}) => {
        this.aiModels = data;
      })
      .catch(err => {
        showWarning(this.toastrService, "failed to load models")
        console.error(err);
      })
    this.userSelectSubscription = this.store
      .pipe(
        select(userFeature.selectUserState),
        take(1)
      )
      .subscribe((user: User) => {
        this.user = user;
        const supportModeEnabled = sessionStorage.getItem('supportMode');
        if (this.user && !this.user.isVpsAssigned && this.user.isAdmin && !supportModeEnabled) {
          this.router.navigate(['/dashboard']);
        } else {
          this.store.dispatch(CharacterActions.getCharactersList({ userUuid: this.user.uuid }));
        }
      })
  }


  deleteCharacter() {
    const charactersIds = [...this.selectedRows];
      this.windowService.open(ConfirmModalComponent, {
        title: `Are You Sure You Want to Delete these Characters?`,
        buttons: buttonsConfig,
        context: {
          textContent: `This action will permanently delete the selected characters and it cannot be undone.`,
          actionCallback: () => this.store.dispatch(CharacterActions.deleteCharacters({ids: charactersIds})),
        },
      });
  }

  onCreateModalSubmit(character: DraftCharacter) {
    this.characterService.createNewCharacter(character).then(({data}) => {
      this.store.dispatch(CharacterActions.addCharacter({ character: data }));
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      this.characterModalRef.close();
    })
  }
  onEditModalSubmit(character: Character) {
    this.characterService.updateCharacter(character).then(({data}) => {
      this.store.dispatch(CharacterActions.updateCharacter({ character: data }));
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      this.characterModalRef.close();
    })
  }


  openNewCharacterModal() {
    forkJoin([
      this.characters$.pipe(take(1)),
    ]).subscribe(([characters]) => {
      this.characterModalRef = this.windowService.open(NewCharacterModalComponent, {
        title: `Create new character`,
        buttons: buttonsConfig,
        closeOnEsc: false,
        context: {
          user: this.user,
          onSubmit: this.onCreateModalSubmit.bind(this),
          onError: showWarning.bind(this, this.toastrService),
          aiModels: this.aiModels,
        },
      });
    });
  }

  openCharacterEditModal(character: Character): void {
    this.characterModalRef = this.windowService.open(NewCharacterModalComponent, {
      title: `Edit character`,
      buttons: buttonsConfig,
      closeOnEsc: false,
      context: {
        user: this.user,
        character: character,
        onSubmit: this.onEditModalSubmit.bind(this),
        onError: showWarning.bind(this, this.toastrService),
        aiModels: this.aiModels,
      },
    });
  }

  openCharacterTestModal(character: Character): void {
    this.characterModalRef = this.windowService.open(ChatModalComponent, {
      title: `Test character chat`,
      buttons: buttonsConfig,
      closeOnEsc: false,
      context: {
        user: this.user,
        character: character,
        onSubmit: () => {},
        onError: showWarning.bind(this, this.toastrService),
      },
    });
  }

  ngOnDestroy() {
    this.userSelectSubscription.unsubscribe();
  }

  private isVideosUsedByStreams(videosIds: number[], allStreams: Stream[]) {
    const playlistVideos: PlaylistVideo[] = allStreams
      .filter(stream => stream.status === StreamStatus.Online)
      .map((activeStream: Stream) => activeStream.playlist?.playlistVideos || [])
      .flat();

    return playlistVideos.some(item => videosIds.some(videoId => item.video.id === videoId));
  }

  isDeleteButtonDisabled() {
    return !this.selectedRows.size;
  }

  changeSelectedRows(selectedRows: Set<string>) {
    this.selectedRows = selectedRows;
  }

  protected readonly convertBytesToReadable = convertBytesToReadable;
}
