import { UploadStatus } from 'enum/upload-status.enum';
import { PollingPayload, ResourceEntry } from '../models';
import { VideoStatusCode } from '../enum/video-status.code';
import { allowedStreamPlatforms } from '../consts';
import { UploadVideoSource } from '../enum/upload-source.enum';

export function getStatusFromProgress(code: number) {
  if (code === -1) {
    return UploadStatus.ERROR;
  } else if (code === 0) {
    return UploadStatus.PROCESSING;
  } else if (0 < code && code < 99) {
    return UploadStatus.UPLOADING;
  } else {
    return UploadStatus.READY;
  }
}

export function isUploadRequiredAuth(video: any) {
  return video.statusCode === VideoStatusCode.ERROR_ACCESS;
}

export function isAuthCodeInPayload(payload: PollingPayload) {
  return Object.values(payload).some((e: any) => e.authCode);
}

export function getSourcePlatformRoute(resource: ResourceEntry) {
  switch (allowedStreamPlatforms[resource.sourcePlatform].toLowerCase()) {
    case UploadVideoSource.TWITCH: {
      return UploadVideoSource.TWITCH;
    }
    case UploadVideoSource.YOUTUBE: {
      return UploadVideoSource.YOUTUBE;
    }
    default: {
      return UploadVideoSource.LOCAL
    }
  }
}
