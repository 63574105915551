import { Component, Input } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { Observable, Subscription, of } from 'rxjs';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';
import { User } from '../../models';
import { Character } from '../../models/character';
import { AiModel } from '../../models/ai-model';
import { countTokens } from '../../utils/characterUtils';

@Component({
  selector: 'app-new-character-modal',
  templateUrl: './new-character-modal.component.html',
  styleUrls: ['./new-character-modal.component.scss']
})
export class NewCharacterModalComponent {
  @Input() user: User;
  @Input() character: Character;
  @Input() aiModels: AiModel[];
  onError: (message: string) => void;
  onSubmit: (...args: any) => void;

  characterForm: FormGroup;
  models$: Observable<string[]>;
  isExpertMode: boolean = false;
  isLoading: boolean = false;
  value: number = 0;
  readonly options: any = {
    temperature: { floor: 0.0, ceil: 2.0, step: 0.01, defaultValue: 1.0, },
    frequency_penalty: { floor: -2, ceil: 2, step: 0.01, defaultValue: 0.0, expert: true },
    presence_penalty: { floor: -2, ceil: 2, step: 0.01, defaultValue: 0.0, expert: true },
    repetition_penalty: { floor: 0, ceil: 2, step: 0.01, defaultValue: 1.0, expert: true },
    top_k: { floor: 0, ceil: 100, defaultValue: 0, expert: true },
    top_p: { floor: 0, ceil: 1, step: 0.01, defaultValue: 1.0, expert: true },
    min_p: { floor: 0, ceil: 1, step: 0.01, defaultValue: 0.0, expert: true },
    top_a: { floor: 0, ceil: 1, step: 0.01, defaultValue: 0.0, expert: true },
  };

  subscription: Subscription;
  tokenCounts = {
    name: 0,
    description: 0,
    personality: 0,
    firstMes: 0,
    questionAnswers: 0,
    model: 0,
  };

  constructor(
    private windowRef: NbWindowRef,
    private fb: FormBuilder,
  ) {
    this.characterForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(250)]],
      description: ['', [Validators.maxLength(1500)]],
      personality: ['', [Validators.maxLength(1500)]],
      firstMes: ['', [Validators.maxLength(200)]],
      questionAnswers: this.fb.array([]),
      model: ['', Validators.required],
      ...this.getCharacterFormSliderOptions()
    });

    this.characterForm.get('name')?.valueChanges.subscribe(value => {
      this.tokenCounts.name = countTokens(!value ? value : value + "Your name " + "system" );
    });
    this.characterForm.get('description')?.valueChanges.subscribe(value => {
      this.tokenCounts.description = countTokens(!value ? value : value + ". You are " + "assistant");
    });
    this.characterForm.get('personality')?.valueChanges.subscribe(value => {
      this.tokenCounts.personality = countTokens(!value ? value : value + ". Your personality is ");
    });
    this.characterForm.get('firstMes')?.valueChanges.subscribe(value => {
      this.tokenCounts.firstMes = countTokens(value);
    });
    this.characterForm.get('questionAnswers')?.valueChanges.subscribe(value => {
      const res = this.getFinalQuestionAnswers(value);
      this.tokenCounts.questionAnswers = countTokens(!res ? res : res + ". Example of messages: ");
    });
    this.characterForm.get('model')?.valueChanges.subscribe(value => {
      this.tokenCounts.model = value ? countTokens(value) : 0;
    });
  }

  getCharacterFormSliderOptions() {
    return Object.fromEntries(Object.entries(this.options).map(([key, { defaultValue, floor, cail }]: any) => ([
      key,
      [defaultValue, [Validators.min(floor), Validators.max(cail)]]
    ])));
  }

  ngOnInit(): void {
    this.models$ = of(this.aiModels.map(({name}) => name));
    if (this.character) {
      this.setUpCharacterData(this.character);
    }
  }

  ngAfterViewInit() {
    const textareas = document.querySelectorAll(`textarea[formControlName="answer"]`);
    textareas.forEach(e => {
      this.autoResize({ target: e });
    });
  }

  setUpCharacterData(character: Character): void {
    const {
      aiModel,
      description,
      firstMes,
      id,
      mesExample,
      metadata = {},
      name,
      personality,
      userId
    } = character;
    const { temperature, frequency_penalty, presence_penalty, repetition_penalty, top_k, top_p, min_p, top_a } = metadata;

    let questionAnswers = mesExample ? this.getArrayOfQuestionAnswers(mesExample) : [];
    questionAnswers.forEach(({ answer, question }: any) => this.addQuestionAnswer(answer, question));
    const sliderValues = Object.fromEntries(
      Object.keys(this.options)
        .filter(key => metadata[key] !== undefined && metadata[key] !== null)
        .map(key => ([key, metadata[key]]))
    );
    this.isExpertMode = Object.keys(sliderValues)
      .filter(key => this.options[key].expert)
      .some(key => this.options[key].defaultValue !== sliderValues[key]) || false;
    this.characterForm.patchValue({
      name: name || '',
      description: description || '',
      personality: personality || '',
      id: id || '',
      metadata: metadata || {},
      firstMes: firstMes || '',
      model: aiModel,
      ...sliderValues
    });
  }

  getOptions(param: string): Options {
    return this.options[param];
  }

  get questionAnswers() {
    return this.characterForm.get('questionAnswers') as FormArray;
  }

  addQuestionAnswer(answer?: string, question?: string) {
    this.questionAnswers.push(this.fb.group({
      question: [question || '', Validators.required],
      answer: [answer || '', Validators.required]
    }));
  }

  removeQuestionAnswer(index: number) {
    this.questionAnswers.removeAt(index);
  }

  generateNewCharacter() {
    let {
      temperature,
      frequency_penalty,
      presence_penalty,
      repetition_penalty,
      top_k,
      top_p,
      min_p,
      top_a,
      questionAnswers,
      model,
      ...character
    } = this.characterForm.value;

    const metadata = Object.fromEntries(Object.entries(this.options)
      .filter(([key, option]: any) => (option.expert ? this.isExpertMode : true)
        && this.characterForm.value[key] !== option.defaultValue
      )
      .map(([key]) => [key, this.characterForm.value[key]])
    )

    return {
      ...(this.character || {}),
      ...character,
      mesExample: this.getFinalQuestionAnswers(questionAnswers),
      aiModel: model,
      userId: this.user.id,
      metadata,
    };
  }

  submit() {
    if (this.characterForm.valid) {
      const character = this.generateNewCharacter();
      this.onSubmit(character);
      this.closeModal();
    } else {
      this.onError('Type all required fields');
    }
  }

  getFinalQuestionAnswers(questionAnswers: any[]) {
    return questionAnswers.reduce((acc, e) => {
      acc += e.question.trim() + "\n" + e.answer.trim() + "\n\n";
      return acc;
    }, '');
  }

  getArrayOfQuestionAnswers(messages: string) {
    return messages.split('\n\n').map(message => {
      const [question, answer] = message.split('\n');
      return { question, answer };
    }).filter(e => e.question && e.answer);
  }

  replaceName(param: string) {
    return param.replace('_', ' ').replace(/\b\w/g, c => c.toUpperCase())
  }

  closeModal() {
    this.windowRef.close();
  }

  toggleExpertMode() {
    this.isExpertMode = !this.isExpertMode;
  }

  getExpertPramsLabels() {
    return Object.entries(this.options).filter(([key, option]: any) => option.expert).map(([key]: any) => key)
  }

  autoResize(event: any) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight + 5 }px`;
  }
}
