import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbToastrService,
  NbWindowService
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { DataState } from 'enum/data-state.enum';
import {
  config,
  Observable,
} from 'rxjs';
import { DashboardConfigChange, User } from '../../models';
import { buttonsConfig } from 'models/nb';
import { characterFeature } from '../../store/reducers/characters.reducers';
import { showError, showSuccess, showWarning } from '../../helpers/nb';
import {
  TriggerWixCompareModalComponent
} from '../../components/trigger-wix-compare-modal/trigger-wix-compare-modal.component';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import { AdminService } from '../../services/admin.service';
import { ConfigService } from '../../services/config.service';
import { Moment } from 'moment';
import {
  AdminSettingOption,
  AdminSettingOptions,
  EditableConfigOption
} from '../../consts/admin-setting';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit, OnDestroy {

  readonly pageTitle = 'Admin Settings';
  readonly DataState = DataState;
  error$: Observable<string | null>;
  pageState$: Observable<DataState>;
  user: User;
  editableConfigs: EditableConfigOption = {};
  debounceId: any = null;

  constructor(
    private windowService: NbWindowService,
    private store: Store,
    private toastrService: NbToastrService,
    private adminService: AdminService,
    private configService: ConfigService,
  ) {
    this.pageState$ = this.store.pipe(select(characterFeature.selectPageState));
    this.error$ = this.store.pipe(select(characterFeature.getCharactersPageError));
    adminService.getAllOptions$().subscribe((configs) => {
      this.editableConfigs = Object.fromEntries(configs
        .filter((config) => AdminSettingOptions[config.key])
        .map((config) => ([config.key, {
          label: AdminSettingOptions[config.key].label,
          type: AdminSettingOptions[config.key].type,
          value: this.mapConfigValue(config.value, AdminSettingOptions[config.key])
            || AdminSettingOptions[config.key].defaultValue,
          tooltip: AdminSettingOptions[config.key].tooltip,
          measurement: AdminSettingOptions[config.key].measurement,
        }]))
      )
    })
  }

  mapConfigValue(configValue: String, settingsConfig: AdminSettingOption) {
    switch (settingsConfig.type) {
      case 'number':
        return Number(configValue);
      case 'time':
        return Number(configValue);
      case 'boolean':
        return configValue === 'true';
      default:
        return configValue;
    }
  }

  onDateSelection(date: Moment, option: any): void {
    const milliseconds = (date.hours() * 60 + date.minutes()) * 60 * 1000;
    this.changeConfig({
      property: option.key,
      oldValue: this.editableConfigs[option.key].value,
      newValue: milliseconds,
    })
    this.editableConfigs[option.key].value = milliseconds;
  }

  onInputChange(event: any, option: any) {
    clearTimeout(this.debounceId);
    this.debounceId = setTimeout(() => {
      this.debounceChangeConfig(event.target.value, option)
    }, 5000);
  }

  debounceChangeConfig(value: any, option: any) {
    this.changeConfig({
      property: option.key,
      oldValue: this.editableConfigs[option.key].value,
      newValue: value,
    })
    this.editableConfigs[option.key].value = value;
  }

  changeConfig(changeEvent: DashboardConfigChange) {
    this.configService.updateConfig$(changeEvent).subscribe({
      error: () => showError(this.toastrService, 'Could not update config. Something went wrong'),
      complete: () => showSuccess(this.toastrService, 'The new settings were successfully saved'),
    });
  }

  triggerWixCompare() {
    this.windowService.open(TriggerWixCompareModalComponent, {
      title: `Trigger Wix Update`,
      buttons: buttonsConfig,
      context: {
        onError: showWarning.bind(this, this.toastrService)
      }
    });
  }

  triggerUpdateAllVps() {
    this.windowService.open(ConfirmModalComponent, {
      title: `Update all Vps`,
      buttons: buttonsConfig,
      context: {
        textContent: `This action will permanently trigger all VPS updating`,
        actionButtonText: 'Update All',
        actionCallback: () => this.updateAllVps()
      },
    });
  }

  updateAllVps() {
    this.adminService.updateAllVps();
    showWarning(
      this.toastrService,
      'updating all VPS',
      7000
    )
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }
}
