import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-information-rectangle',
  templateUrl: './information-rectangle.component.html',
  styleUrls: ['./information-rectangle.component.scss']
})
export class InformationRectangleComponent {
  @Input() isVertical: boolean = false;
  @Input() size: number = 200;
  @Input() src: string = '';
  @Input() icon: string = '';
  @Input() tooltip: string = '';
}
