import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbTabComponent,
  NbToastrService,
  NbWindowRef,
  NbWindowService
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { ConfirmModalComponent } from 'components/confirm-modal/confirm-modal.component';
import { NewVideoModalComponent } from 'components/new-video-modal/new-video-modal.component';
import { DataState } from 'enum/data-state.enum';
import { FileCreation, PlaylistUploadCreation, ResourceEntry, ResourceUploadPayload } from 'models/resource';
import {
  BehaviorSubject, filter, forkJoin, from, map, mergeMap, Observable, of, pairwise, race, Subscription, switchMap, take,
  timer, zip
} from 'rxjs';
import { VideoPageActions, VideoUploadActions } from 'store/actions/videos.actions';
import {
  getAllResources, getAuthorizationCode,
  getResourcePageError,
  resourceFeature
} from 'store/reducers/videos.reducers';
import { ResourceService } from 'services/resource.service';
import { VpsService } from 'services/vps.service';
import { UserActions } from 'store/actions/user.actions';
import { splitFileName } from 'helpers/resource.helper';
import { Stream } from 'models/stream';
import { streamFeature } from 'store/reducers/stream.reducers';
import { StreamActions } from 'store/actions/stream.actions';
import { StreamService } from 'services/stream.service';
import { AxiosResponse } from 'axios';
import { StreamStatus } from 'enum/streams.enum';
import { PlaylistCreation, PlaylistVideo } from 'models/playlist';
import { User } from '../../models';
import { Router } from '@angular/router';
import { userFeature } from 'store/reducers/user.reducers';
import { showError, showWarning } from 'helpers/nb';
import { buttonsConfig } from 'models/nb';
import { convertBytesToReadable } from '../../pipes/video-size.pipe';
import { UserService } from '../../services/user.service';
import { Actions, ofType } from '@ngrx/effects';
import { isAuthCodeInPayload } from '../../helpers/videoStatus.helper';
import { UploadVideoSource } from '../../enum/upload-source.enum';
import {
  NewPlaylistVideosModalComponent
} from '../../components/new-playlist-videos-modal/new-playlist-videos-modal.component';
import { PlaylistService } from '../../services/playlist.service';
import { PlatformAccessStatuses } from '../../consts/platform-access-statuses';

@Component({
  selector: 'app-videos',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {

  readonly pageTitle = 'My Videos';
  readonly DataState = DataState;
  readonly purchasedPlanSizeBytes = 200 * 1024 * 1024 * 1024;
  readonly freePlanSizeBytes = 150 * 1024 * 1024 * 1024;

  error$: Observable<string | null>;
  pageState$: Observable<DataState>;
  resources$: Observable<ResourceEntry[]>;
  countOfUploadingVideos: number = 0;
  vpsStatistics: { usedSpace: number, count: number, length: number } = {
    length: 0,
    usedSpace: 0,
    count: 0
  };
  streams$: Observable<Stream[]>;
  user: User;
  userSelectSubscription: Subscription;
  spaceAvailableByPlan: number = 0; // todo: Get value from state
  newVideoModalRef: NbWindowRef<any, any>;
  newPlaylistVideosModalRef: NbWindowRef<any, any>;
  selectedRows: Set<number> = new Set();
  tableResources$: Observable<ResourceEntry[]>;
  tabTitle: BehaviorSubject<string> = new BehaviorSubject('Horizontal');

  constructor(
    private windowService: NbWindowService,
    private store: Store,
    private resourceService: ResourceService,
    private streamService: StreamService,
    private vpsService: VpsService,
    private toastrService: NbToastrService,
    private router: Router,
    private userService: UserService,
    private actions$: Actions,
    private playlistService: PlaylistService,
  ) {
    this.pageState$ = this.store.pipe(select(resourceFeature.selectPageState));
    this.error$ = this.store.pipe(select(getResourcePageError));
    this.resources$ = this.store.pipe(select(getAllResources));
    this.store.pipe(select(resourceFeature.getResourcePageStatistics)).subscribe((data: any) => {
      this.vpsStatistics = data;
    });
    this.streams$ = this.store.pipe(select(streamFeature.selectStreamState));
    this.tableResources$ = from(this.tabTitle)
      .pipe(
        switchMap((tabTitle) => this.getFilteredResources((resource: ResourceEntry) =>
          tabTitle === 'Horizontal' ? !resource.isVerticalOrientation : resource.isVerticalOrientation
        ))
      )
  }

  ngOnInit(): void {
    this.store.dispatch(VideoPageActions.getVideos());
    this.userSelectSubscription = this.store
      .pipe(
        select(userFeature.selectUserState),
        take(1)
      )
      .subscribe((user: User) => {
        this.user = user;
        const supportModeEnabled = sessionStorage.getItem('supportMode');
        if (this.user && !this.user.isVpsAssigned && this.user.isAdmin && !supportModeEnabled) {
          this.router.navigate(['/dashboard']);
        }
        this.spaceAvailableByPlan = user.trialPeriod ? this.freePlanSizeBytes : this.purchasedPlanSizeBytes;
        this.validateSpaceUsage();
      })

    this.streamService.fetchStreams().then(({data}: AxiosResponse<Stream[]>) => {
      this.store.dispatch(StreamActions.getStreams({streams: data}))
    })

    this.store.pipe(select(resourceFeature.getProcessedVideosCount))
      .subscribe((videoInUpload) => {
        this.countOfUploadingVideos = videoInUpload;
      });
  }

  getFilteredResources(callback = (resource: ResourceEntry) => {}) {
    return this.resources$.pipe(
      map((resources: ResourceEntry[]) => resources.filter(callback)),
    );
  }

  validateSpaceUsage() {
    if (this.spaceAvailableByPlan === 0) {
      return;
    }

    this.resources$.subscribe((data) => {
      const freeSpaceOnVps = this.spaceAvailableByPlan - this.vpsStatistics.usedSpace;

      if (freeSpaceOnVps < 0) {
        showWarning(this.toastrService, 'The space limit is reached. Deleting last uploaded videos.');

        let spaceWillBeRestored = 0;
        const videosToDelete = data.filter((video) => video.uploadStatus === 100)
          .sort((a, b) => {
            if (a.id > b.id) {
              return -1;
            } else if (a.id < b.id) {
              return 1;
            } else {
              return 0;
            }
          })
          .filter((video) => {
            if ((spaceWillBeRestored + freeSpaceOnVps) <= 0) {
              spaceWillBeRestored += video.size || 0;
              return true;
            }

            return false;
          });
        videosToDelete.forEach((video) => this.store.dispatch(VideoPageActions.removeVideo({id: video.id})));
      }
    })
  }

  deleteResources() {
    const videoIds = [...this.selectedRows];
    zip(this.streams$, this.tableResources$).pipe(take(1)).subscribe(([allStreams, resources]) => {
      const videoIsUsed: boolean = this.isVideosUsedByStreams(videoIds, allStreams);
      if (videoIsUsed) {
        showWarning(this.toastrService, 'To delete this videos, you must first stop the streams that is currently using it. The videos cannot be removed while it is still being utilized by the stream.')
        return;
      }

      this.windowService.open(ConfirmModalComponent, {
        title: `Are You Sure You Want to Delete these Videos?`,
        buttons: buttonsConfig,
        context: {
          textContent: `This action will permanently delete the ${videoIds.length} selected videos, and it cannot be undone.`,
          actionCallback: () => {
            this.selectedRows.clear();
            return this.store.dispatch(VideoPageActions.removeVideos({ids: videoIds}))
          },
        },
      });
    })
  }

  private closeModal() {
    this.newVideoModalRef.close();
    if (this.newPlaylistVideosModalRef) {
      this.newPlaylistVideosModalRef.close();
    }
  }

  getPlatformCheckAccess(resourceSource: UploadVideoSource) {
    switch (resourceSource) {
      case UploadVideoSource.YOUTUBE: {
        return UploadVideoSource.YOUTUBE;
      }
      case UploadVideoSource.YOUTUBE_PLAYLIST: {
        return UploadVideoSource.YOUTUBE;
      }
      default: {
        return;
      }
    }
  }

  onModalSubmit(resourceSource: UploadVideoSource, rawResources: File[] | string[], playlistName: string | undefined) {
    const platformCheckAccess = this.getPlatformCheckAccess(resourceSource);

    if (platformCheckAccess) {
      this.store.dispatch(VideoUploadActions.checkServiceAccess({ platformName: platformCheckAccess }));
      this.actions$.pipe(
        ofType(VideoUploadActions.authorizationRequired),
        take(1)
      )
        .subscribe(({ authCode, error, status = 1 }) => {
          if (error) {
            console.error(error);
            showError(this.toastrService, "Failed to check access. Error: " + (typeof error === 'string' ? error : error.message));
            this.newVideoModalRef.close();
            return;
          }
          if (authCode) {
            showWarning(this.toastrService, `Youtube access required. ${PlatformAccessStatuses[status]}`)
          } else if (resourceSource === UploadVideoSource.YOUTUBE_PLAYLIST) {
            this.getPlaylistVideos(resourceSource, String(rawResources[0]).toString());
          } else {
            this.uploadVideos(resourceSource, rawResources, playlistName);
          }
        });
    } else {
      this.uploadVideos(resourceSource, rawResources, playlistName);
    }
  }

  getPlaylistVideos(resourceSource: UploadVideoSource, rawResources: string) {
    this.resourceService.getPlaylistVideos(resourceSource, rawResources).then(playlistUploading => {
      this.openNewPlaylistVideosModal(playlistUploading);
    }).catch((e) => {
      let message = e?.response?.data?.message || 'Could not get playlist videos. Please try again';
      this.store.dispatch(VideoUploadActions.authorizationRequired({ authCode: false }));
      showError(this.toastrService, message);
    });
  }

  uploadVideos(resourceSource: UploadVideoSource, rawResources: File[] | string[], playlistName: string | undefined) {
    const entitiesPayload: FileCreation[] = resourceSource === UploadVideoSource.LOCAL
      ? (rawResources as File[])
        .map((file: File) => ({ name: splitFileName(file)[0], size: file.size}))
      : (rawResources as string[])
        .map((videoId: string) => ({ name: videoId, size: 0}))
    Promise.all([
      this.vpsService.getVpsForCurrentUser(),
      this.resourceService.createEntities(resourceSource, entitiesPayload),
    ]).then(([userVps, videoEntities]) => {
      if (playlistName) {
        const playlist: PlaylistCreation = {
          name: playlistName,
          playlistVideos: videoEntities.map((video, index) => ({
            order: index,
            video,
          }))
        }

        this.playlistService.saveOrUpdatePlaylist(playlist)
      }
      this.store.dispatch(VideoUploadActions.authorizationRequired({ authCode: null }));

      for (let i = 0; i < rawResources.length; i++) {
        let fileEntry = videoEntities[i];
        const payload: ResourceUploadPayload = {
          entry: fileEntry,
          file: rawResources[i] as File,
          sourceId: rawResources[i] as string,
        };
        this.store.dispatch(UserActions.saveUserVps({userVps}));
        this.store.dispatch(VideoPageActions.addVideo({payload: fileEntry}));
        if (resourceSource === UploadVideoSource.LOCAL) {
          this.store.dispatch(VideoUploadActions.uploadLocalVideo({payload}));
        } else {
          this.store.dispatch(VideoUploadActions.startPolling());
          this.store.dispatch(VideoUploadActions.uploadUrlVideo({payload, source: resourceSource}));
        }
      }

      race(
        this.actions$.pipe(ofType(VideoUploadActions.uploadProgressUpdate, VideoUploadActions.startPolling)),
        timer(5000).pipe(mergeMap(() => of(null)))
      )
        .pipe(take(1))
        .subscribe((action: any) => {
          if (action && action.type === VideoUploadActions.uploadProgressUpdate.type && action.payload) {

            if (!isAuthCodeInPayload(action.payload)) {
              this.closeModal()
              return;
            }

            this.saveAuthCodeIfRequired(videoEntities, action.payload)

            this.store.select(getAuthorizationCode)
              .pipe(
                pairwise(),
                filter(([previous, current]) => !!previous && !current),
                take(1)
              )
              .subscribe(_ => {
                this.closeModal();
              });
          } else {
            this.closeModal();
          }
        });
    }).catch(() => showWarning(this.toastrService, 'Could not upload video. Please try again'));
  }

  openNewPlaylistVideosModal(playlist: PlaylistUploadCreation) {
    this.newPlaylistVideosModalRef = this.windowService.open(NewPlaylistVideosModalComponent, {
      title: `Select videos to upload`,
      buttons: buttonsConfig,
      closeOnEsc: false,
      context: {
        playlist: playlist,
        onSubmit: this.onModalSubmit.bind(this),
        onError: showWarning.bind(this, this.toastrService),
      },
    });
    this.newPlaylistVideosModalRef.onClose.subscribe(() => this.newVideoModalRef.close());
  }

  openNewVideoModal() {
    forkJoin([
      this.resources$.pipe(take(1)),
    ]).subscribe(([videos]) => {
      this.newVideoModalRef = this.windowService.open(NewVideoModalComponent, {
        title: `Select video source`,
        buttons: buttonsConfig,
        closeOnEsc: false,
        context: {
          space_available: this.spaceAvailableByPlan - this.vpsStatistics.usedSpace,
          onSubmit: this.onModalSubmit.bind(this),
          onError: showWarning.bind(this, this.toastrService),
        },
      });
    });
  }

  ngOnDestroy() {
    this.userSelectSubscription.unsubscribe();
  }

  private isVideosUsedByStreams(videosIds: number[], allStreams: Stream[]) {
    const playlistVideos: PlaylistVideo[] = allStreams
      .filter(stream => stream.status === StreamStatus.Online)
      .map((activeStream: Stream) => activeStream.playlist?.playlistVideos || [])
      .flat();

    return playlistVideos.some(item => videosIds.some(videoId => item.video.id === videoId));
  }

  getTooltipForAddVideo() {
    return (this.spaceAvailableByPlan < this.vpsStatistics.usedSpace && 'Free space exceeded!')
      || (!this.user.assignedVps?.isVpsUp && 'Server for streaming is down!') || null
  }

  isAddButtonDisabled() {
    return (this.spaceAvailableByPlan < this.vpsStatistics.usedSpace)
      || !this.user.assignedVps?.isVpsUp
      || this.countOfUploadingVideos > 0
      || false;
  }

  isDeleteButtonDisabled() {
    return !this.selectedRows.size
      || !this.user.assignedVps?.isVpsUp
      || false;
  }

  saveAuthCodeIfRequired(videoEntities: ResourceEntry[], actionPayload: { [x: string]: any; }) {
    let entriesIds = videoEntities.map((e: ResourceEntry) => e.id);
    const entriesWithAuthCode = Object
      .keys(actionPayload)
      .filter(key => actionPayload[key]?.authCode && entriesIds.includes(Number(key)));
    if (entriesWithAuthCode.length) {
      const payload = actionPayload[entriesWithAuthCode[0]];
      this.store.dispatch(VideoUploadActions.authorizationRequired({
        authCode: payload.authCode
      }));
    }
  }

  onTabChange(tab: NbTabComponent) {
    this.tabTitle.next(tab.tabTitle);
    this.selectedRows = new Set();
  }

  changeSelectedRows(selectedRows: Set<number>) {
    this.selectedRows = selectedRows;
  }

  protected readonly convertBytesToReadable = convertBytesToReadable;
}
