import { createFeature, createReducer, on } from '@ngrx/store';
import { StreamActions } from '../actions/stream.actions';
import { Stream } from '../../models/stream';

export type StreamState = Stream[] | [];

const initialStreamState: StreamState = [];

export const streamFeature = createFeature({
  name: 'stream',
  reducer: createReducer(
    initialStreamState,
    on(StreamActions.getStreams, (state, {streams}) => (streams)),
    on(StreamActions.addStream, (state, {stream}) => ([...state, stream])),
    on(StreamActions.deleteStream, (state, {id}) => (state.filter((pl) => id !== pl.id))),
    on(StreamActions.updateStream, (state, {stream}) => (state.map((pl) => {
      return pl.id === stream.id ? {...pl, ...stream} : pl
    })))),
});
