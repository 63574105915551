<app-top-bar
  [page-title]="pageTitle"
  [is-loading]="spaceAvailableByPlan === 0"
  [label-count]="(resources$ | async)?.length"
  [labels]="(resources$ | async)?.length ? [
    ['Video'],
    ['Used ' + ( vpsStatistics.usedSpace | videoSize) + ' of', convertBytesToReadable(spaceAvailableByPlan)]
  ] : []"
/>

<ng-container *ngIf="(pageState$ | async) as pageState" [ngSwitch]="pageState">
  <!-- SPINNER -->
  <ng-container *ngSwitchCase="DataState.LOADING">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </ng-container>
  <!-- TABLE -->
  <ng-container *ngSwitchCase="DataState.LOADED">

    <div
      *ngIf="!(resources$ | async)?.length"
      class="d-flex justify-content-center align-items-center h-75 my-videos-action-header-empty"
    >
      <div class="my-videos-action-header d-flex flex-column">
        <img src="assets/logo-wo-text.svg" style="width: 300px; height: 200px; padding-left: 45px;"/>
        ​<button
          nbButton
          status="primary"
          (click)="openNewVideoModal()"
          [disabled]="!user.assignedVps?.isVpsUp"
          [ngStyle]="{
              'backgroundColor': 'var(--button-dark)',
              'border': 'none'
          }"
        >Upload your first video</button>
      </div>
    </div>

    <app-card *ngIf="(resources$ | async)?.length">
      <div class="my-videos-action-header d-flex my-videos-action-header-buttons">
        <div [ngbTooltip]="getTooltipForAddVideo()">
          <button
            nbButton
            status="primary"
            (click)="openNewVideoModal()"
            [disabled]="isAddButtonDisabled()"
            [ngStyle]="{
            'backgroundColor': 'var(--button-dark)',
            'border': 'none'
          }"
          >ADD VIDEO</button>
          <svg xmlns="http://www.w3.org/2000/svg"
               class="mx-3"
               *ngIf="countOfUploadingVideos > 0"
               ngbTooltip="New video upload can be started after the previous videos completed uploading."
               width="28" height="28" fill="#00d68f" viewBox="0 0 256 256">
            <path d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z" opacity="0.2"></path>
            <path d="M140,180a12,12,0,1,1-12-12A12,12,0,0,1,140,180ZM128,72c-22.06,0-40,16.15-40,36v4a8,8,0,0,0,16,0v-4c0-11,10.77-20,24-20s24,9,24,20-10.77,20-24,20a8,8,0,0,0-8,8v8a8,8,0,0,0,16,0v-.72c18.24-3.35,32-17.9,32-35.28C168,88.15,150.06,72,128,72Zm104,56A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path></svg>
        </div>
        <button
          nbButton
          status="primary"
          (click)="deleteResources()"
          [disabled]="isDeleteButtonDisabled()"
          [ngStyle]="{
            'backgroundColor': 'var(--button-dark)',
            'border': 'none'
          }"
        >
          DELETE VIDEOS
        </button>
      </div>
      <nb-tabset (changeTab)="onTabChange($event)">
        <nb-tab tabTitle="Horizontal" class="my-videos-tab">
        </nb-tab>
        <nb-tab tabTitle="Vertical" class="my-videos-tab">
        </nb-tab>
      </nb-tabset>
      <app-video-table
        [resources$]="tableResources$"
        [selectedRows]="selectedRows"
        [user]="user"
      />
    </app-card>
  </ng-container>
  <!-- ERROR -->
  <ng-container *ngSwitchCase="DataState.ERROR">
    <div class="d-flex flex-column my-videos-error">
      <img class="my-videos-error-img" src="assets/sadcloud.png" alt="No videos">
      <p class="my-videos-error-text">Failed to load videos, please try again</p>
    </div>
  </ng-container>

</ng-container>
