<div  *ngIf="!link"
  class="sidebar-button d-flex align-items-center me-4 mb-1"
>
  <div class="d-flex flex-row">
    <span class="ms-4 button-icon d-flex align-items-center">
      <i class="ph-thin {{ icon }}"></i>
    </span>
    <span class="ms-2 button-title d-flex align-items-center">{{ title }}</span>
  </div>
</div>

<div
  *ngIf="link"
  class="sidebar-button d-flex align-items-center me-4 mb-1"
  [routerLinkActive]="[ link ? 'active' : '']"
  routerLink="{{ link }}"
>
  <div class="d-flex flex-row">
    <span class="ms-4 button-icon d-flex align-items-center">
      <i class="ph-thin {{ icon }}"></i>
    </span>
    <span class="ms-2 button-title d-flex align-items-center">{{ title }}</span>
  </div>
</div>
