<div class="card-patination">
  <div class="card-patination-selector">
    Items per page:
    <nb-select
      [(selected)]="pageSize"
      (selectedChange)="changeSize()"
    >
      <nb-option [value]="5">5</nb-option>
      <nb-option [value]="10">10</nb-option>
      <nb-option [value]="25">25</nb-option>
      <nb-option [value]="100">100</nb-option>
    </nb-select>
  </div>

  <div class="card-patination-pages">
    Page {{ currentPage }} of {{ getMaxPages() }}
  </div>

  <div class="card-patination-buttons">
    <button [disabled]="currentPage === 1" nbButton ghost (click)="previous()"><nb-icon icon="arrow-ios-back"/></button>
    <button [disabled]="currentPage === getMaxPages()" nbButton ghost (click)="next()"><nb-icon icon="arrow-ios-forward"/></button>
  </div>

</div>
