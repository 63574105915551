import { Component, Input } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';


@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Input() actionCallback: () => void;
  @Input() cancelCallback: () => void;
  @Input() textContent: string = '';
  @Input() actionButtonText: string = 'delete';

  constructor(private windowRef: NbWindowRef) {
  }

  submit() {
    this.actionCallback && this.actionCallback()
    this.windowRef.close();
  }

  closeModal() {
    this.cancelCallback && this.cancelCallback();
    this.windowRef.close();
  }
}
