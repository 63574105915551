import { Component, Input } from '@angular/core';
import { NbToastrService, NbWindowRef } from '@nebular/theme';
import { SuggestionService } from '../../services/suggestion.service';
import { showError, showSuccess } from '../../helpers/nb';


@Component({
  selector: 'app-give-suggestion-modal',
  templateUrl: './give-suggestion-modal.component.html',
  styleUrls: ['./give-suggestion-modal.component.scss']
})
export class GiveSuggestionModalComponent {
  @Input() actionCallback: () => void;
  @Input() cancelCallback: () => void;
  @Input() textContent: string = '';

  suggestion: string = '';

  constructor(
    private windowRef: NbWindowRef,
    private suggestionService: SuggestionService,
    private toastrService: NbToastrService ) {
  }

  submit() {
    this.suggestionService.giveSuggestion(this.suggestion)
      .then(_ => {
        showSuccess(this.toastrService, "suggestion given successfully");
      })
      .catch(error => {
        console.error(error);
        showError(this.toastrService, "Failed to give suggestion");
      });
    this.windowRef.close();
  }

  closeModal() {
    this.cancelCallback && this.cancelCallback();
    this.windowRef.close();
  }
}
