import { Pipe, PipeTransform } from '@angular/core';
import emojify from 'emojify.js';

@Pipe({ name: 'textToEmoji' })
export class TextToEmojiPipe implements PipeTransform {
  transform(text: string): string {
    if (!text) return text;

    emojify.setConfig({
      img_dir: 'https://cdnjs.cloudflare.com/ajax/libs/emojify.js/1.1.0/images/basic',
    });
    return emojify.replace(text);
  }
}
