import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Character, ChatMessage } from '../../models/character';
import { Stream } from '../../models';

export const CharacterActions = createActionGroup({
  source: 'Character',
  events: {
    'Get Characters List': props<{ userUuid?: string }>(),
    'Get Characters List Success': props<{ characters: Character[] }>(),
    'Get Characters List Failure': props<{ error: string | null }>(),
    'Add Character': props<{ character: Character }>(),
    'Update Character': props<{ character: Character }>(),
    'Delete Character': props<{ id: string }>(),
    'Delete Characters': props<{ ids: string[] }>(),
  }
});

export const CharacterChatActions = createActionGroup({
  source: 'CharacterChat',
  events: {
    'Send Message': props<{ message: ChatMessage, character: Character, stream: Stream | undefined }>(),
    'Add Message': props<{ message: ChatMessage, tokens: Number | undefined | null }>(),
    'Clear Messages': emptyProps,
  }
});
