import { Component, OnDestroy } from '@angular/core';
import { NbToastrService, NbWindowRef, NbWindowService } from '@nebular/theme';
import { Stream, StreamCreation, Playlist, buttonsConfig, Character, User } from '../../models';
import { select, Store } from '@ngrx/store';
import { StreamService } from '../../services/stream.service';
import { StreamActions } from '../../store/actions/stream.actions';
import { PlatformStreamUrl, StreamPlatform } from 'enum/streams.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/environment';
import { allowedStreamPlatforms } from '../../consts';
import { StreamCharacterModalComponent } from '../stream-character-modal/stream-character-modal.component';
import { showError, showWarning } from '../../helpers/nb';
import { Observable, take } from 'rxjs';
import { characterFeature } from '../../store/reducers/characters.reducers';
import { VideoSource, VideoSourceToShow } from '../../enum/upload-source.enum';
import { VideoUploadActions } from '../../store/actions/videos.actions';
import { Actions, ofType } from '@ngrx/effects';
import { PlatformAccessStatuses } from '../../consts/platform-access-statuses';

@Component({
  selector: 'app-new-stream-modal',
  templateUrl: './new-stream-modal.component.html',
  styleUrls: ['./new-stream-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: '0',
        width: '0'
      })),
      transition('void <=> *', animate('500ms ease-in-out'))
    ])
  ]
})
export class NewStreamModalComponent implements OnDestroy {
  editableStream: StreamCreation | Stream;
  isTextCharacterBlocked: boolean = false;
  playlists: Playlist[] = [];
  streams: Stream[] = [];
  onError: (message: string) => void;
  testCharacter: (stream: any) => void;
  viewOnly = false;
  user: User;
  characters$: Observable<Character[]>;
  onCharacterSubmit: () => void;
  showStreamingKey: boolean = false;
  readonly StreamPlatform = StreamPlatform;
  accessLoading: boolean = false;

  constructor(
    private windowRef: NbWindowRef,
    private streamService: StreamService,
    private store: Store,
    private windowService: NbWindowService,
    private toastrService: NbToastrService,
    private actions$: Actions,
  ) {
    this.characters$ = this.store.pipe(select(characterFeature.getAllCharacters));
  }

  updateField({value}: any, fieldName: string) {
    this.editableStream = {...this.editableStream, [fieldName]: value};
  }

  submit() {
    this.editableStream = {
      ...this.editableStream,
      playlist: this.playlists.find((pl) => pl.id == this.editableStream?.playlistId)
    }
    if (!this.platformAllowedToUseChatbot()) {
      delete this.editableStream.accessTokenId;
      delete this.editableStream.character;
      delete this.editableStream.scenario;
    }
    const currentStreamId = this.editableStream.id;
    let isDuplicatedKey;

    const character = this.streams
      .find(stream => stream.id === currentStreamId);

    const characterChanged = character && (
      character.platformName !== this.editableStream.platformName
      || character.streamKey !== this.editableStream.streamKey
    );

    if (currentStreamId) {
      isDuplicatedKey = this.streams
        .filter(stream => (stream.id !== currentStreamId && stream.streamKey))
        .some(stream => stream.streamKey === this.editableStream.streamKey);
    } else {
      isDuplicatedKey = this.streams
        .filter(stream => stream.streamKey)
        .some(stream => stream.streamKey === this.editableStream.streamKey);
    }

    if (isDuplicatedKey) {
      this.onError("This streaming key already exist!");
      return;
    }

    const submitFunction = () => {
      this.streamService.createOrUpdateStream(this.editableStream).then(({data}) => {
        if (currentStreamId) {
          this.store.dispatch(StreamActions.updateStream({stream: data}))
        } else {
          this.store.dispatch(StreamActions.addStream({stream: data}))
        }
      });
      this.windowRef.close();
    }

    if (this.editableStream.id && this.editableStream.platformName && characterChanged) {
      this.onCharacterSubmit = () => this.getServiceAuthorizationRequired(
        this.editableStream.id,
        this.editableStream.platformName,
        submitFunction,
      );
      this.onCharacterSubmit();
    } else {
      submitFunction();
    }
  }

  onPlatformChange(value: number) {
    const streamingUrl = PlatformStreamUrl[value]
    this.updateField({value: streamingUrl}, "streamUrl")
  }

  closeModal() {
    this.windowRef.close();
  }

  selectComparator(v1: any, v2: any) {
    return v1 == v2
  }

  isVertical(playlist: Playlist) {
    if (!!playlist?.playlistVideos?.length
      && !playlist.playlistVideos.some(({video}) => !video.isVerticalOrientation)
    ) {
      return " - VERTICAL";
    } else {
      return "";
    }
  }

  editCharacter(isNewCharacter?: boolean) {
    this.windowService.open(StreamCharacterModalComponent, {
      title: `${isNewCharacter ? 'Assign' : 'Edit'} stream character`,
      buttons: buttonsConfig,
      closeOnEsc: false,
      context: {
        user: this.user,
        stream: this.editableStream,
        onSubmit: this.onCharacterModalSubmit.bind(this),
        onError: showWarning.bind(this, this.toastrService),
      },
      windowClass: "scroll-modal"
    });
  }

  retryAccess() {
    this.onCharacterSubmit();
  }

  getServiceAuthorizationRequired(streamId: number = 0, platform: number = 1, submitFunction: () => void) {
    this.accessLoading = true;
    let name = "";
    let platformName = "";
    if (platform  === StreamPlatform.Twitch) {
      name = VideoSourceToShow.TWITCH;
      platformName = VideoSource.TWITCH;
    } else {
      name = VideoSourceToShow.YOUTUBE;
      platformName = VideoSource.YOUTUBE;
    }

    this.store.dispatch(VideoUploadActions.checkServiceAccess({ platformName, streamId }));
    this.actions$.pipe(
      ofType(VideoUploadActions.authorizationRequired),
      take(1)
    )
      .subscribe(({ authCode, error, status = 1 }) => {
        if (error) {
          console.error(error);
          showError(this.toastrService, "Failed to check access. Error: " + (typeof error === 'string' ? error : error.message));
          return;
        }
        if (authCode) {
          showWarning(this.toastrService, `${name} access required. ${PlatformAccessStatuses[status]}`);
        } else {
          this.accessLoading = false;
          this.store.dispatch(VideoUploadActions.authorizationRequired({ authCode: null }));
          submitFunction();
        }
      });
  }

  onCharacterModalSubmit(data: any) {
    let submitFunction = () => {
      this.accessLoading = false;
      const { scenario, characterId } = data;
      this.characters$.pipe(take(1)).subscribe((characters) => {
        let character = characters.find(character => character.id === characterId)
        this.editableStream = { ...this.editableStream, scenario, character };
      });
    }

    this.onCharacterSubmit = () => this.getServiceAuthorizationRequired(data.stream.id, data.stream.platformName, submitFunction);
    this.onCharacterSubmit();
  }

  unassignCharacter() {
    this.store.dispatch(VideoUploadActions.authorizationRequired({ authCode: null }));
    this.accessLoading = false;
    this.editableStream = { ...this.editableStream, scenario: undefined, character: undefined };
  }

  getCharacterName() {
    return this.editableStream?.character?.name || '';
  }

  isUserInAllowedToChatbot() {
    return this.user?.subscriptionPlan?.chatbot;
  }

  platformAllowedToUseChatbot() {
    return this.editableStream.platformName === 1 || this.editableStream.platformName === 2;
  }

  ngOnDestroy() {
    this.onCharacterSubmit = () => {};
    this.store.dispatch(VideoUploadActions.authorizationRequired({ authCode: null }));
  }

  protected readonly environment = environment;
  protected readonly allowedStreamPlatforms = allowedStreamPlatforms;
}
