import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbSortDirection, NbSortRequest, NbToastrService, NbWindowService } from '@nebular/theme';
import { PageChange } from 'models/events';
import { LSKeys } from 'enum/local-storage-key.enum';
import { SuggestionService } from '../../services/suggestion.service';
import { Suggestion } from '../../models/suggestion';
import {ConfirmModalComponent} from "../../components/confirm-modal/confirm-modal.component";
import {buttonsConfig} from "../../models";

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit, OnDestroy {

  tableColumnLabels = {
    checkbox: 'Checkbox',
    id: 'Id',
    userEmail: 'User Email',
    suggestion: 'Suggestion',
  } as const;
  tableColumnNames = Object.fromEntries(Object.entries(this.tableColumnLabels).map(([k, v]) => [v, k]));
  readonly allTableColumns = Object.keys(this.tableColumnLabels);

  sortColumn: string = '';
  sortRequest: NbSortRequest | null;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  pageSize: number;
  pageNumber: number = 1;
  LSPageSizeKey = LSKeys.managementPageSize;

  suggestions: Suggestion[] = [];
  isSelectedAll: boolean = false;
  selectedRows: Set<number> = new Set();
  private hoveredRow: Suggestion | null = null;

  constructor(
    private toastrService: NbToastrService,
    private windowService: NbWindowService,
    private suggestionService: SuggestionService,
  ) {
  }

  async ngOnInit() {
    let pageSize = localStorage.getItem(this.LSPageSizeKey) ?? '10';
    this.pageSize = parseInt(pageSize);

    this.suggestionService.getAll()
      .then(({data}) => this.suggestions = data)
      .catch((error) => {console.log(error)})
  }

  isDeleteButtonDisabled() {
    return !this.selectedRows.size;
  }

  deleteSuggestions() {
    const suggestionIds = [...this.selectedRows];

    this.windowService.open(ConfirmModalComponent, {
      title: `Are You Sure You Want to Delete these suggestions?`,
      buttons: buttonsConfig,
      context: {
        textContent: `This action will permanently delete the ${suggestionIds.length} selected suggestions, and it cannot be undone.`,
        actionCallback: () => this.suggestionService.deleteSuggestions(suggestionIds)
          .then(_ => this.filterSuggestions(suggestionIds)),
      },
    });
  }

  filterSuggestions(suggestionIds: number[]) {
    this.selectedRows = new Set();
    this.suggestions = this.suggestions.filter(({ id }) => !suggestionIds.includes(id));
  }

  toggleAllSelection(event: Event) {
      this.isSelectedAll = !this.isSelectedAll;
      if (this.isSelectedAll) {
        this.suggestions.forEach((suggestions) => suggestions.id && this.selectedRows.add(suggestions.id));
      } else {
        this.selectedRows.clear();
      }
  }

  toggleSelection(row: Suggestion) {
    if (this.selectedRows.has(row.id)) {
      this.selectedRows.delete(row.id);
    } else {
      this.selectedRows.add(row.id);
    }

    this.checkIsAllShouldBeSelected();
  }

  checkIsAllShouldBeSelected() {
    this.isSelectedAll = !!this.suggestions.length && this.suggestions.length === this.selectedRows.size;
  }

  isSelected(row: Suggestion): boolean {
    return this.selectedRows.has(row.id);
  }

  getDirection(column: string): NbSortDirection {
    if (column === this.sortColumn) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  onPageChange(pageEvent: PageChange) {
    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.currentPage;
  }

  changeSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortRequest = sortRequest;
    this.sortDirection = sortRequest.direction;
  }


  ngOnDestroy(): void {
  }
}
