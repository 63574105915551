import { Component } from '@angular/core';

@Component({
  selector: 'app-card',
  template: `
  <div class="card">
    <div class="card-body" [ngStyle]="{'padding': '20px 24px'}">
      <ng-content></ng-content>
    </div>
  </div>
  `,
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

}
