<div class="{{ fullWidth ? '' : 'confirm-modal' }}">
  <div style="overflow: hidden;">
    <p class="mb-3" *ngIf="textContent">{{ textContent }}</p>
    <div *ngIf="textHtml" [innerHTML]="textHtml"></div>
    <ng-container #dynamicComponentContainer/>
  </div>
</div>

<app-modal-action-buttons
  action-button-text="{{ actionButtonText }}"
  close-button-text="{{ closeButtonText }}"
  [actionCallback]="submit.bind(this)"
  [cancelCallback]="closeModal.bind(this)"
  [actionButtonDisabled]="actionButtonDisabled"
  [actionButtonBlocked]="actionButtonBlocked"
/>
