<ng-container *ngIf="(playlists$ | async) as playlists" [ngSwitch]="playlists">
  <app-top-bar
    [page-title]="pageTitle"
    [is-loading]="isPageLoading"
    [label-count]="playlists.length"
    [labels]="[['Playlist']]"
  />

  <ng-container *ngIf="isPageLoading">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="playlists.length && !isPageLoading">
    <div class="playlist-action-header">
      <button nbButton
              status="primary"
              class="playlist-action-header-button"
              (click)="openNewPlaylistModal()"
      >Add Playlist</button>
    </div>

    <div class="playlist-page">
      <app-playlist-card *ngFor="let playlist of playlists"
                        [playlist]="playlist"
                        [isPlaylistUsing]="isPlaylistUsing(playlist)"
                        [removeCallback]='onPlaylistRemove.bind(this)'
                        [editCallback]='onPlaylistEdit.bind(this, playlist)'
                        [openDetailsCallback]='openPlaylistDetails.bind(this, playlist)'
      />
    </div>
  </ng-container>

  <ng-container *ngIf="!playlists.length && !isPageLoading">
    <div class="d-flex justify-content-center align-items-center h-75">
      <div class="my-videos-action-header d-flex flex-column">
        <img src="assets/logo-wo-text.svg" style="width: 300px; height: 200px; padding-left: 45px;"/>
        ​<button
          nbButton
          status="primary"
          (click)="openNewPlaylistModal()"
          [ngStyle]="{
              'backgroundColor': 'var(--button-dark)',
              'border': 'none'
          }"
        >Create your first playlist</button>
      </div>
    </div>
  </ng-container>

</ng-container>
