import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { Stream, StreamCreation } from '../models/stream';
import { Observable } from 'rxjs';
import { ResourceResponse } from '../models/resource';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class StreamService {

  private readonly apiUrl = environment.apiUrl + '/stream';

  constructor(private http: HttpClient) {
  }

  private createStream(stream: StreamCreation) {
    return axios.post(`${this.apiUrl}`, stream);
  }

  private updateStream(stream: Stream) {
    return axios.put(`${this.apiUrl}`, stream);
  }

  createOrUpdateStream(stream: Stream | StreamCreation) {
    if (!stream.id) {
      return this.createStream(stream as StreamCreation)
    } else {
      return this.updateStream(stream as Stream);
    }
  }

  fetchStreams$ = <Observable<ResourceResponse<Stream[]>>>
    this.http.get<ResourceResponse<Stream[]>>(`${this.apiUrl}`);

  fetchStreams() {
    return axios.get(`${this.apiUrl}`)
  }

  stopUserStreams(userEmail: string) {
    return axios.get(`${this.apiUrl}/stop/all/${userEmail}`)
  }

  startStreams(streamId: number) {
    return axios.get(`${this.apiUrl}/start/${streamId}`)
  }

  stopStreams(streamId: number) {
    return axios.get(`${this.apiUrl}/stop/${streamId}`)
  }

  fetchErrorsStreams() {
    return axios.get(`${this.apiUrl}/error`)
  }

  assignCharacter(characterId: string, scenario: string, streamId: number) {
    return this.http.post<boolean>(`${this.apiUrl}/${streamId}/character`, { scenario, characterId });
  }

  editCharacter(characterId: string, scenario: string, streamId: number) {
    return this.http.patch<boolean>(`${this.apiUrl}/${streamId}/character`, { scenario, characterId });
  }

  unassignCharacter(streamId?: number) {
    return this.http.delete<boolean>(`${this.apiUrl}/${streamId}/character`);
  }

}
