import { Pipe, PipeTransform } from '@angular/core';
import { NbSortRequest, NbTreeGridDataSource } from '@nebular/theme';

@Pipe({
  name: 'sortNbTable'
})
export class SortNbTablePipe implements PipeTransform {

  transform(tableData: NbTreeGridDataSource<any> | null, sortRequest: NbSortRequest | null) {
    if (sortRequest && tableData) tableData.sort(sortRequest);
    return tableData;
  }
}
