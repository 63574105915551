<div class="character-form">
  <div class="character-form-menu">
    <label class="character-form-label">Select presets</label>
    <nb-select
      placeholder="Select Presets"
      [(selected)]="tempPreset"
      (selectedChange)="onChangePreset($event)"
      class="character-form-menu-selector"
    >
      <nb-option *ngFor="let preset of presets" [value]="preset">{{ preset }}</nb-option>
    </nb-select>
  </div>

  <form [formGroup]="characterForm" (ngSubmit)="submit()" class="character-form-content">
    <div class="form-group">
      <label class="character-form-label" for="name">Name of the Character*</label>
      <input
        nbInput
        id="name"
        placeholder="type name of character"
        formControlName="name"
        type="text"
        class="form-control character-form-input"
        maxlength="64"
      >
      <app-input-error [control]="characterForm.get('name')" [label]="'Name'"/>
      <div class="character-form-input-token-count">Tokens: {{ tokenCounts.name }}</div>
    </div>

    <div class="form-group">
      <label class="character-form-label" for="description">Description of the Character</label>
      <textarea
        nbInput
        id="description"
        placeholder="type description of character"
        formControlName="description"
        class="form-control character-form-input"
        maxlength="1500"
        (input)="autoResize($event)"
      ></textarea>
      <app-input-error [control]="characterForm.get('description')" [label]="'Description'"/>
      <div class="character-form-input-token-count">Tokens: {{ tokenCounts.description }}</div>
    </div>

    <div class="form-group">
      <label class="character-form-label" for="personality">Personality of the Character</label>
      <textarea
        nbInput
        id="personality"
        placeholder="type personality of character"
        formControlName="personality"
        class="form-control character-form-input"
        maxlength="1500"
        (input)="autoResize($event)"
      ></textarea>
      <app-input-error [control]="characterForm.get('personality')" [label]="'Personality'"/>
      <div class="character-form-input-token-count">Tokens: {{ tokenCounts.personality }}</div>
    </div>

    <div class="form-group">
      <label class="character-form-label" for="firstMes">Greeting Message</label>
      <input
        nbInput
        id="firstMes"
        placeholder="type greeting message"
        formControlName="firstMes"
        type="text"
        class="form-control character-form-input"
        maxlength="200"
      >
      <app-input-error [control]="characterForm.get('firstMes')" [label]="'Greeting'"/>
      <div class="character-form-input-token-count">Tokens: {{ tokenCounts.firstMes }}</div>
    </div>

    <div formArrayName="questionAnswers" class="form-group character-form-input-question-answers">
      <label>Question-Answers</label>
      <div
        *ngFor="let qa of questionAnswers.controls; let i = index"
        [formGroupName]="i"
        class="qa-pair character-form-input-question-answers-block"
      >
        <input
          nbInput
          formControlName="question"
          placeholder="Question"
          class="form-control character-form-input character-form-input-question-answers-block-answer"
        >
        <textarea
          nbInput
          formControlName="answer"
          placeholder="Answer"
          class="form-control character-form-input-question-answers-block-answer character-form-input"
          rows="1"
          (input)="autoResize($event)"
        ></textarea>
        <button nbButton status="danger" type="button" (click)="removeQuestionAnswer(i)">Remove</button>
      </div>
      <div class="character-form-input-token-count">
        <button nbButton status="primary" type="button" (click)="addQuestionAnswer()">Add Question-Answer</button>
        <div>
          Tokens: {{ tokenCounts.questionAnswers }}
        </div>
      </div>

    </div>

    <div class="form-group character-form-input-models">
      <label class="character-form-label" for="model">LLM Model</label>
      <app-search-select [options$]="models$" id="model" formControlName="model" [placeholder]="'Select model'"/>
      <app-input-error [control]="characterForm.get('model')" [label]="'Model'"/>
      <div class="character-form-input-token-count">
        Tokens: {{ tokenCounts.model }}
      </div>
    </div>

    <div class="form-group character-form-input-temperature">
      <ng-container [ngTemplateOutlet]="tooltip" [ngTemplateOutletContext]="{option:'temperature'}"/>
      <ngx-slider
        id="temperature"
        formControlName="temperature"
        [(value)]="characterForm.value.temperature"
        [options]="getOptions('temperature')"
      />
    </div>

    <div class="expert-mode">
      <nb-checkbox [checked]="isExpertMode" (change)="toggleExpertMode()" >For expert users</nb-checkbox>
      <span>
        Modifying these settings may impact bot performance and reduce quality.
      </span>
    </div>
    <div *ngIf="isExpertMode" class="character-form-expert-mode-params">
      <button nbButton type="button" (click)="resetDefault()" class="character-form-expert-mode-params-reset">
        Reset to default settings
      </button>

      <div *ngFor="let param of getExpertPramsLabels()" class="form-group">

        <div *ngIf="getOptions(param).inputs">
          <ng-container [ngTemplateOutlet]="tooltip" [ngTemplateOutletContext]="{option:param}"/>
          <div formArrayName="stop">
            <div
              *ngFor="let stopControl of stopControls(); let i = index; trackBy: trackByStop.bind(this)"
              class="character-form-input-stop"
            >
              <input
                nbInput
                [formControlName]="i"
                fieldSize="small"
                placeholder="stop"
                class="form-group character-form-input character-form-input-stop-input"
              >
              <button nbButton type="button" status="danger" (click)="removeStop(i)">Delete</button>
            </div>
          </div>
          <button nbButton type="button" status="primary" class="character-form-input-stop-button" (click)="addStop()">
            Add Stop Word
          </button>
          <app-input-error [control]="characterForm.get('stop')" [label]="'Stop'"/>
        </div>

        <div *ngIf="getOptions(param).slider">
          <ng-container [ngTemplateOutlet]="tooltip" [ngTemplateOutletContext]="{option:param}"/>
          <ngx-slider [id]="param" [formControlName]="param" [(value)]="characterForm.value[param]" [options]="getOptions(param)"/>
        </div>

        <div *ngIf="getOptions(param).checkbox" class="character-form-input-checkbox">
          <ng-container [ngTemplateOutlet]="tooltip" [ngTemplateOutletContext]="{option:param}"/>
          <nb-checkbox [id]="param" [formControlName]="param">
            trim
          </nb-checkbox>
        </div>
      </div>
    </div>

    <ng-template #tooltip let-option='option'>
      <label
        class="character-form-label"
        [for]="option"
        [ngbTooltip]="tooltipTemplate"
      >
        {{ replaceName(option) }}
        <nb-icon icon="info-outline"/>
      </label>
      <ng-template #tooltipTemplate>
        <div class="character-form-tooltip">
          <span [innerHTML]="getOptions(option).tooltip"></span>
        </div>
      </ng-template>
    </ng-template>

  </form>
</div>

<app-modal-action-buttons
  class="character-form-buttons"
  action-button-text="Save"
  [actionCallback]="submit.bind(this)"
  [cancelCallback]="closeModal.bind(this)"
  [action-button-loading]="isLoading"
/>
