export interface AdminSettingOption {
  type: "number" | "time" | "boolean" | undefined;
  defaultValue: number;
  label: string;
  tooltip?: string;
  measurement?: string;
}

interface AdminSettingOptionsInterface {
  [key: string]: AdminSettingOption;
}

export const AdminSettingOptions: AdminSettingOptionsInterface = {
  CHATBOT_LOW_ACTIVITY_INTERACTION_TIME: {
    type: "time",
    defaultValue: 1800000,
    label: "Chatbot interaction time",
    tooltip: "The amount of time after which the chatbot sends a message if no one is chatting."
  },
  CHATBOT_MAX_COST_USAGE: {
    type: "number",
    defaultValue: 3.0,
    label: "Chatbot cost limitation",
    tooltip: "The maximum spendable amount in a month for a single chatbot.",
    measurement: "€/month"
  },
}

type AdminSettingOptionKeys = keyof typeof AdminSettingOptions;

export interface ConfigOption {
  key: AdminSettingOptionKeys,
  value: string
}

export interface EditableConfigOption {
  [key: AdminSettingOptionKeys | string] :{
    type: "number" | "time" | "boolean" | undefined;
    value: any,
    label: string;
    tooltip?: string;
    measurement?: string;
  }
}

