import { Component, Input, OnInit } from '@angular/core';
import { Playlist, PlaylistCreation, ResourceEntry } from '../../models';
import { getStatusFromProgress } from '../../helpers/videoStatus.helper';
import { UploadStatus } from 'enum/upload-status.enum';


@Component({
  selector: 'app-video-list-table',
  templateUrl: './video-list-table.component.html',
  styleUrls: ['./video-list-table.component.scss']
})
export class VideoListTableComponent implements OnInit {
  @Input() getNotAddedVideos: (resources: ResourceEntry[]) => ResourceEntry[];
  @Input() addVideoToPlaylist: (orderNumber: number, data: ResourceEntry) => void;
  @Input() addAllVideosToPlaylist: () => void;
  @Input('searchableResources') searchableResources: ResourceEntry[] = [];
  @Input() editablePlaylist: Playlist | PlaylistCreation;
  resourceListColumnNames = {
    name: 'Video Name',
    actions: 'Actions',
  } as const;

  readonly resourceListTableColumns = Object.values(this.resourceListColumnNames);
  readonly UploadStatus = UploadStatus;

  constructor() {}

  ngOnInit() {}

  getReadyToUseVideos(resources: ResourceEntry[]): ResourceEntry[] {
    return resources.filter(res => getStatusFromProgress(res.uploadStatus) === this.UploadStatus.READY)
  }

  getAvailableVideosToSelect(resources: ResourceEntry[]) {
    resources = this.getNotAddedVideos(resources);
    resources = this.getReadyToUseVideos(resources);
    return resources;
  }
}
