<nb-layout>

  <!--  <nb-layout-header fixed>-->
  <!--  &lt;!&ndash; Insert header here &ndash;&gt;-->
  <!--  </nb-layout-header>-->

  <nb-layout-column *ngIf="sessionToken" class="p-0">
    <div
      class="d-flex flex-row"
      style="background: #F7F8FA; height: 100vh"
    >
      <app-sidebar></app-sidebar>
      <div class="w-100 page-container overflow-auto">
        <router-outlet *ngIf="user && (user.isVpsAssigned || user.isAdmin)"></router-outlet>
        <div *ngIf="user && ((!user.isVpsAssigned && !user.isAdmin) || !user.subscriptionPlan) "
             class="h-100 d-flex justify-content-center align-items-center flex-column">
          <div *ngIf="!user.subscriptionPlan && !user.isVpsAssigned && !user.isAdmin" class="d-flex flex-column">
            <span>You have no subscription plan!</span>
            <div class="d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-warning mt-2 text-uppercase"
                (click)='document.location.href=environment.homePage + "/membership"'
              >
                Start Stream right now!
              </button>
            </div>
          </div>
          <div *ngIf="user.subscriptionPlan && !user.isVpsAssigned && !user.isAdmin" class="d-flex flex-column">
            <span>We are working on configuring the dedicated streaming server to you, it will take maximum few hours!</span>
            <div class="d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-warning mt-2 text-uppercase"
                (click)='document.location.href=environment.homePage + "/contact"'
              >
                Contact us in case of any issues!
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="isSupportAlertOpen" class="support-mode-alert-container">
          <nb-alert (close)="onSupportAlertClose()" closable accent="warning" outline="basic">You're currently in Support Mode. All the changes performed on this account will affect real user's data.</nb-alert>
        </div>
      </div>
    </div>
  </nb-layout-column>

</nb-layout>
