<div *ngIf="authCode || (authorizationCode$ | async) as authCode" class="authorization-code">
  <p class="authorization-text">
    Authorization required:
  </p>
  <a
    *ngIf="platform === StreamPlatform.YouTube"
    [href]="'https://www.google.com/device?user_code=' + authCode"
    target="_blank"
    class="authorization-text-link"
  >
    Go to Google Device Authorization
  </a>
  <a
    *ngIf="platform === StreamPlatform.Twitch"
    [href]="'https://www.twitch.tv/activate?device-code=' + authCode"
    target="_blank"
    class="authorization-text-link"
  >
    Go to Twich Device Authorization
  </a>

  <p class="disclosure-text">
    <a
      *ngIf="platform === StreamPlatform.YouTube"
      href="{{ environment.homePage }}/google-api-disclosure"
      target="_blank"
    >
      Learn more about how we use Google API data
    </a>
    <a
      *ngIf="platform === StreamPlatform.Twitch"
      href="{{ environment.homePage }}/twich-api-disclosure"
      target="_blank"
    >
      Learn more about how we use Twich API data
    </a>
  </p>

  <div *ngIf="retryButton" class="authorization-code-buttons">
    <button nbButton status="success" (click)="this.retryAccess.emit($event)">
      <nb-icon icon="repeat-outline"/>
      retry
    </button>
    <button nbButton (click)="this.cancelAccess.emit($event)">
      close
    </button>
  </div>
</div>
