import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlaylistComponent } from 'pages/playlist/playlist.component';
import { VideoComponent } from './pages/video/video.component';
import { StreamComponent } from './pages/stream/stream.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CharacterComponent } from 'pages/character/character.component';
import { VpsManagementComponent } from './pages/vps-management/vps-management.component';
import { AdminSettingsComponent } from './pages/adminSetting/admin-settings.component';
import { SuggestionsComponent } from './pages/suggestions/suggestions.component';

const routes: Routes = [
  {path: '', redirectTo: 'videos', pathMatch: "full"},
  {path: 'videos', component: VideoComponent, title: 'JustStream - My Videos'},
  {path: 'playlists', component: PlaylistComponent, title: 'JustStream - My Playlists'},
  {path: 'streams', component: StreamComponent, title: 'JustStream - My Streams'},
  {path: 'dashboard', component: DashboardComponent, title: 'JustStream - Dashboard'},
  {path: 'character', component: CharacterComponent, title: 'JustStream - My Characters'},
  {path: 'admin-settings', component: AdminSettingsComponent, title: 'JustStream - Admin Settings'},
  {path: 'vps-management', component: VpsManagementComponent, title: 'JustStream - Vps Management'},
  {path: 'suggestions', component: SuggestionsComponent, title: 'JustStream - Suggestions from Customers'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
