<ng-container ngSwitch="{{getVideoStatusCode()}}">

  <ng-container *ngSwitchCase="1">
    <nb-progress-bar [value]="100" status="warning">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only"></span>
      </div>
      <span style="margin-left: 5px;">Processing...</span>
    </nb-progress-bar>
  </ng-container>

  <ng-container *ngSwitchCase="2">
    <nb-progress-bar
      status="warning"
      size="small"
      [value]="progress"
      [displayValue]="true"
    />
  </ng-container>

  <ng-container *ngSwitchCase="3">
    <span class="my-video-table-status">Ready for use</span>
  </ng-container>

  <ng-container *ngSwitchCase="4">
  <span
    class="my-video-table-status grey"
    ngbTooltip="The uploaded video is temporary unavailable due to not supported video codec. Please convert video to h264 codec."
  >
    Temporary Unavailable
  </span>
  </ng-container>

  <ng-container *ngSwitchCase="5">
    <nb-progress-bar
      status="danger"
      size="small"
      [value]="100"
    >
      Failed to upload
    </nb-progress-bar>
  </ng-container>

  <ng-container *ngSwitchCase="6">
  <span class="my-video-table-status grey" ngbTooltip="Youtube access missed while video was loading">
    Youtube access missed
    <button ghost size="small" nbButton (click)="checkAccess()">
      <nb-icon  icon="repeat-outline"/>
    </button>
  </span>
  </ng-container>

  <ng-container *ngSwitchCase="7">
  <span
    class="my-video-table-status grey"
    ngbTooltip="Video size is to large to upload. Max availabe size is 8GB"
  >
    Video size is to large
  </span>
  </ng-container>

  <ng-container *ngSwitchCase="0">
    <nb-progress-bar [value]="100" status="warning">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only"></span>
      </div>
      <span style="margin-left: 5px;">Processing....</span>
    </nb-progress-bar>
  </ng-container>

</ng-container>
