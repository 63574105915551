import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  NbMenuItem, NbMenuService, NbPosition,
  NbSortDirection,
  NbSortRequest, NbToastrService,
  NbWindowService
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { ConfirmModalComponent } from 'components/confirm-modal/confirm-modal.component';
import { DataState } from 'enum/data-state.enum';
import { PageChange } from 'models/events';
import {
  filter, map,
  Observable, Subscription,
  take
} from 'rxjs';
import {
  getResourcePageError,
  resourceFeature
} from 'store/reducers/videos.reducers';
import { Stream } from 'models/stream';
import { streamFeature } from 'store/reducers/stream.reducers';
import { buttonsConfig, User } from '../../models';
import { LSKeys } from 'enum/local-storage-key.enum';
import { Character } from '../../models/character';
import { CharacterActions } from '../../store/actions/characters.actions';
import { showWarning } from '../../helpers/nb';
import { AxiosResponse } from 'axios';
import { StreamActions } from '../../store/actions/stream.actions';
import { StreamService } from '../../services/stream.service';

@Component({
  selector: 'app-character-table',
  templateUrl: './character-table.component.html',
  styleUrls: ['./character-table.component.scss']
})
export class CharacterTableComponent implements OnInit, OnDestroy {
  @Input('characters$') characters$: Observable<Character[]>;
  @Input('selectedRows') selectedRows: Set<String>;
  @Input() validateCharacterUsage$: (character: Character) => Observable<boolean>;
  @Input() isCharacterUsedByStreams: (character: Character, allStreams: Stream[]) => boolean;
  @Input() getUsedCharactersSet: (allStreams: Stream[]) => Set<string | undefined>;
  @Output() openTestModal = new EventEmitter<Character>();
  tableColumnLabels = {
    checkbox: 'Checkbox',
    series: 'Series',
    name: 'Name',
    aiModel: 'Model',
    actions: 'Actions',
  } as const;
  tableColumnNames = Object.fromEntries(Object.entries(this.tableColumnLabels).map(([k, v]) => [v, k]));
  readonly allTableColumns = Object.keys(this.tableColumnLabels);

  sortColumn: string;
  sortRequest: NbSortRequest | null;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  error$: Observable<string | null>;
  pageState$: Observable<DataState>;
  countOfUploadingVideos: number = 0;
  vpsStatistics: { usedSpace: number, count: number, length: number } = {
    length: 0,
    usedSpace: 0,
    count: 0
  };
  streams$: Observable<Stream[]>;
  user: User;

  pageSize: number;
  pageNumber: number = 1;
  LSPageSizeKey = LSKeys.videoPageSize;

  characterTableContextItems: NbMenuItem[] = [
    {title: 'Edit', data: this.editCharacter.bind(this)},
    {title: 'Test Character', data: this.testCharacter.bind(this)},
    {title: 'Delete', data: this.deleteCharacter.bind(this)}
  ];
  contextItemsPosition: NbPosition = NbPosition.BOTTOM;
  contextMenuTag: string = 'my-character-table-context-menu';
  contextMenuActiveRow: any;
  menuServiceSubscription: Subscription;
  isSelectedAll: boolean = false;
  @Output() openEditModal = new EventEmitter<Character>();


  constructor(
    private windowService: NbWindowService,
    private store: Store,
    private nbMenuService: NbMenuService,
    private toastrService: NbToastrService,
  ) {
    this.pageState$ = this.store.pipe(select(resourceFeature.selectPageState));
    this.error$ = this.store.pipe(select(getResourcePageError));
    this.store.pipe(select(resourceFeature.getResourcePageStatistics)).subscribe((data: any) => {
      this.vpsStatistics = data;
    });
    this.streams$ = this.store.pipe(select(streamFeature.selectStreamState));
    this.menuServiceSubscription = this.nbMenuService.onItemClick()
      .pipe(
        filter(({tag}) => tag === this.contextMenuTag),
        map(({item: {data}}) => data),
      )
      .subscribe(callback => {
        if (callback) callback(this.contextMenuActiveRow);
      });
  }

  ngOnInit(): void {
    let pageSize = localStorage.getItem(this.LSPageSizeKey) ?? '10';
    this.pageSize = parseInt(pageSize);

    this.store.pipe(select(resourceFeature.getProcessedVideosCount))
      .subscribe((videoInUpload) => {
        this.countOfUploadingVideos = videoInUpload;
      });
  }

  deleteCharacter(character: Character) {
    this.validateCharacterUsage$(character).subscribe((characterIsUsed) => {
      if (characterIsUsed) {
        showWarning(this.toastrService, 'To delete this character, you must first stop the stream that is currently using it. The character cannot be removed while it is still being utilized by the stream.')
        return;
      }

      this.windowService.open(ConfirmModalComponent, {
        title: `Are You Sure You Want to Delete this Character?`,
        buttons: buttonsConfig,
        context: {
          textContent: `This action will permanently delete the character "${character.name}" and it cannot be undone.`,
          actionCallback: () => this.store.dispatch(CharacterActions.deleteCharacter({id: character.id})),
        },
      });
    });
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortRequest = sortRequest;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  onPageChange(pageEvent: PageChange) {
    this.pageSize = pageEvent.pageSize;
    this.pageNumber = pageEvent.currentPage;
  }

  ngOnDestroy() {
    this.menuServiceSubscription.unsubscribe();
  }

  toggleAllSelection(event: Event) {
    this.characters$.pipe(take(1)).subscribe((characters) => {
      this.isSelectedAll = !this.isSelectedAll;
      if (this.isSelectedAll) {
        characters.forEach(character => this.selectedRows.add(character.id));
      } else {
        this.selectedRows.clear();
      }
    });
  }

  checkIsAllShouldBeSelected() {
    this.characters$.pipe(take(1)).subscribe((characters) => {
      this.isSelectedAll = characters.length === this.selectedRows.size;
    });
  }

  toggleSelection(row: Character) {
    if (this.selectedRows.has(row.id)) {
      this.selectedRows.delete(row.id);
    } else {
      this.selectedRows.add(row.id);
    }

    this.checkIsAllShouldBeSelected();
  }

  isSelected(row: Character): boolean {
    return this.selectedRows.has(row.id);
  }

  editCharacter(character: Character) {
    this.openEditModal.emit(character);
  }

  testCharacter(character: Character) {
    this.openTestModal.emit(character);
  }
}
