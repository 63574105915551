import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios, { AxiosResponse } from 'axios';
import { environment } from '../environments/environment';
import { select, Store } from '@ngrx/store';
import { UserActions } from 'store/actions/user.actions';
import { userFeature } from './store/reducers/user.reducers';
import { VpsManagementComponent } from './pages/vps-management/vps-management.component';
import { User } from './models/user';
import { resourceFeature } from 'store/reducers/videos.reducers';
import { UserService } from './services/user.service';
import { from, interval, mergeMap } from 'rxjs';
import { StreamService } from './services/stream.service';
import { NbToastrService } from '@nebular/theme';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  protected readonly document = document;

  sessionToken: string | undefined;
  supportMode: string | undefined;
  supportUserId: string | undefined;
  user: User | undefined;

  isSupportAlertOpen: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private userService: UserService,
    private streamService: StreamService,
    private toastrService: NbToastrService,
  ) {}

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.sessionToken = params['sessionToken'];
        this.supportMode = params['supportMode'];
        this.supportUserId = params['supportUserId'];

        if (this.supportMode && this.supportUserId) {
          sessionStorage.setItem('supportMode', this.supportMode);
          sessionStorage.setItem('supportUserId', this.supportUserId);
          document.location.href = "/";
        }

        if (this.sessionToken) {
          localStorage.setItem('sessionToken', this.sessionToken);

          document.location.href = "/";
          this.sessionToken = localStorage.getItem('sessionToken') || undefined;
        }
      });

    axios.interceptors.request.use(function (config) {
      const sessionToken = localStorage.getItem('sessionToken') || undefined;
      const supportMode = sessionStorage.getItem('supportMode');
      const supportUserId = sessionStorage.getItem('supportUserId');

      if (sessionToken) {
        config.headers['Authorization'] = sessionToken;
      }

      if (supportMode && supportUserId) {
        config.headers['X-Support-UserId'] = supportUserId;
        config.headers['X-Support-Mode-Enabled'] = supportMode;
      }

      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });

    console.log(`Started environment [${environment.production ? 'Production' : 'Development'}]`)

    this.sessionToken = localStorage.getItem('sessionToken') || undefined;

    if (this.sessionToken) {
      this.validateToken();
    } else {
      location.href = `${environment.homePage}/?openAdmin=1&redirectTo=${environment.host}`;
    }

    this.validateRoutes();

    interval(10*1000).pipe(
      mergeMap(() => from(this.userService.getCurrentUser()))
    ).subscribe(data => {
      this.store.dispatch(UserActions.setUser({user: data.data}));
    })

    // Confirm exit if user uploading videos
    this.store.pipe(select(resourceFeature.getProcessedVideosCount))
    .subscribe((videoInUpload) => {
      if (videoInUpload > 0) {
        window.addEventListener('beforeunload', this.unloadHandler, { capture: true });
      } else {
        window.removeEventListener('beforeunload', this.unloadHandler, { capture: true });
      }
    });

    if (sessionStorage.getItem('supportMode')) {
      this.isSupportAlertOpen = true;
    }
  }

  ngAfterViewInit() {
    // this.streamService.fetchErrorsStreams().then(({data}: AxiosResponse<Stream[]>) => {
    //   data.forEach((stream: Stream) => {
        // if (!stream.errorMessage) {
        //   this.toastrService.show( stream.errorMessage, `Stream Error. Id: `+ stream.id, {
        //     status: 'danger',
        //     duration: 10000,
        //   });
        // } else {
        //   let errors = stream.errorMessage.split("\n");
        //   errors.forEach((error: string) => {
        //     this.toastrService.show( error, `Stream Error. Id: `+ stream.id, {
        //       status: 'danger',
        //       duration: 10000,
        //     });
        //   })
        // }
      // })
    // }) // todo: enable in future
  }

  onSupportAlertClose() {
    this.isSupportAlertOpen = false;
  }

  // Prevent browser from loading a drag-and-dropped file
  @HostListener("dragover", ["$event"])
  onDragover(event: Event) {
    event.preventDefault();
  }

  @HostListener("drop", ["$event"])
  onDrop(event: Event) {
    event.preventDefault();
  }

  private unloadHandler($event: any) {
    $event.preventDefault();
    return ($event.returnValue = "Are you sure you want to leave this page? If you leave now, the video upload will be interrupted and may not be completed.");
  }

  private validateRoutes() {
    this.store.pipe(select(userFeature.selectUserState)).subscribe((user: any) => {
      if (user && user.isAdmin) {
        this.user = user;
        this.router.config.push({ path: 'vps-management', component: VpsManagementComponent });
      }
    })
  }

  private validateToken() {
    axios.post(`${environment.apiUrl}/users/validate`, { token: this.sessionToken }).then((resp) => {
      if (resp && resp.data.id) {
        this.store.dispatch(UserActions.setUser({ user: resp.data }))
        this.user = resp.data;

        localStorage.setItem('user', JSON.stringify(resp.data));
        // this.router.navigate(['/']);
      }
    });
  }

  protected readonly environment = environment;
}
