import { Injectable } from '@angular/core';
import axios, { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { User } from 'models/user';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly apiUrl = environment.apiUrl;

  constructor() {
  }

  getAll(): Promise<AxiosResponse<User[]>> {
    return axios.get(`${this.apiUrl}/users`)
  }

  getCurrentUser(): Promise<AxiosResponse<User>> {
    return axios.get(`${this.apiUrl}/users/current`)
  }

  validateUserByWix(email: string | undefined): Promise<AxiosResponse<any>> {
    return axios.post(`${this.apiUrl}/users/validate/wix`, {email})
  }

  validateAllUserByWix(): Promise<AxiosResponse<any>> {
    return axios.post(`${this.apiUrl}/users/validate/wix/all`)
  }
}

