import { Component, Input, OnChanges } from '@angular/core';
import { User } from 'models/user';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { userFeature } from 'store/reducers/user.reducers';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnChanges{
  @Input('labels') labels: (string|number)[][] = [];
  @Input('is-loading') isLoading: boolean = false;
  @Input('page-title') title: string = "";
  @Input('label-count') labelCount: number | undefined = undefined;

  constructor(private store: Store) {}

  transformed_labels: string[];
  user$: Observable<any>; // User | {}

  ngOnInit() {
    this.transformLabels();
    this.user$ = this.store.pipe(select(userFeature.selectUserState))
  }

  ngOnChanges() {
    this.transformLabels();
  }

  private transformLabels() {
    this.transformed_labels = this.labels.map((t, index) => {
      if (index === 0 && this.labelCount) {
        return [
          this.labelCount,
          ...t.map((item, index) => item
            + (t.length - 1 === index && this.labelCount && this.labelCount > 1 ? "s" : "")),
        ].join(' ')
      }
      return t.join(' ')
    });
  }
}
