import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbToastrService,
  NbWindowService
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { DataState } from 'enum/data-state.enum';
import {
  Observable,
} from 'rxjs';
import { User } from '../../models';
import { buttonsConfig } from 'models/nb';
import { characterFeature } from '../../store/reducers/characters.reducers';
import { showWarning } from '../../helpers/nb';
import {
  TriggerWixCompareModalComponent
} from '../../components/trigger-wix-compare-modal/trigger-wix-compare-modal.component';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit, OnDestroy {

  readonly pageTitle = 'Admin Settings';
  readonly DataState = DataState;
  error$: Observable<string | null>;
  pageState$: Observable<DataState>;
  user: User;

  constructor(
    private windowService: NbWindowService,
    private store: Store,
    private toastrService: NbToastrService,
  ) {
    this.pageState$ = this.store.pipe(select(characterFeature.selectPageState));
    this.error$ = this.store.pipe(select(characterFeature.getCharactersPageError));
  }

  ngOnInit(): void {
  }

  triggerWixCompare() {
    this.windowService.open(TriggerWixCompareModalComponent, {
      title: `Trigger Wix Update`,
      buttons: buttonsConfig,
      context: {
        onError: showWarning.bind(this, this.toastrService)
      }
    });
  }

  ngOnDestroy() {
  }
}
