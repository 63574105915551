import { Component, Input, OnInit } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AiModel, Character, ChatMessage, Stream } from '../../models';
import { select, Store } from '@ngrx/store';
import { CharacterChatActions } from '../../store/actions/characters.actions';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { characterFeature } from '../../store/reducers/characters.reducers';
import { allowedStreamPlatforms } from '../../consts';

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss']
})
export class ChatModalComponent implements OnInit {
  @Input('character') character: Character;
  @Input('stream') stream?: Stream;
  @Input('scrollToBottom') scrollToBottom: () => void;
  @Input('chatBotUsageBlocked$') chatBotUsageBlocked$: BehaviorSubject<boolean>;
  @Input('aiModels') aiModels: AiModel[];
  @Input('streams') streams: Stream[];
  username: string;
  chatForm: FormGroup;
  isLoading: boolean = false;
  messages$: Observable<ChatMessage[]>;
  tokens$: Observable<Number>;
  subscription: Subscription = new Subscription();
  streamsWithChatbotScenario: boolean = false;
  streamId?: number;
  options: any;

  constructor(
    private windowRef: NbWindowRef,
    private fb: FormBuilder,
    private store: Store,
  ) {
    this.chatForm = this.fb.group({
      message: ['', Validators.required]
    });
    this.messages$ = this.store.pipe(select(characterFeature.getChatMessages));
    this.tokens$ = this.store.pipe(select(characterFeature.getTokens));
  }

  ngOnInit(): void {
    if (this.character.firstMes) {
      this.store.dispatch(CharacterChatActions.addMessage({
        message: {
          name: this.character.name.replace(/ /g, ""),
          content: this.character.firstMes,
          role: "assistant",
        },
        tokens: 0
      }));
    }
    if (this.streams) {
      this.options = this.streams
        .filter(({ character }) => !!character)
        .map(({ character, id, platformName }) => ({
          tooltip: character?.description,
          value: id,
          label: allowedStreamPlatforms[platformName] + " Stream id: " + id
        }));
      if (this.stream) {
        this.streamId = this.stream.id;
      }
    }
    this.subscription.add(from(this.chatBotUsageBlocked$).subscribe(this.shouldCloseModal.bind(this)));
    this.subscription.add(this.messages$.subscribe(messages => {
      setTimeout(() => this.scrollToBottom(), 100);
    }));
  }
  ngOnDestroy() {
    this.store.dispatch(CharacterChatActions.clearMessages());
    this.subscription.unsubscribe();
  }

  shouldCloseModal(chatBotUsageBlocked: boolean) {
    if (chatBotUsageBlocked) {
      const aiModel = this.aiModels.find(({name}) => name === this.character.aiModel);
      aiModel && !aiModel.hasFree && this.closeModal();
    }
  }

  sendMessage(): void {
    if (this.chatForm.valid) {
      this.store.dispatch(CharacterChatActions.sendMessage({
        message: {
          content: this.chatForm.value.message,
          name: this.username ? this.username.replace(/ /g, "") : this.username,
          role: "user",
        },
        character: this.character,
        stream: this.streamId && this.getStreamByStreamId() || this.stream
      }))
      this.chatForm.reset();
    }
  }

  closeModal(): void {
    this.windowRef.close();
  }

  onPlatformChange(value: number) {
    this.streamId = value;
  }

  getStreamByStreamId() {
    return this.streams.find(({id}) => id && this.streamId === id);
  }

  selectComparator(v1: any, v2: any) {
    return v1 == v2
  }

  protected readonly allowedStreamPlatforms = allowedStreamPlatforms;
}
