<app-top-bar page-title='Suggestions from Customers'/>

<app-card>
  <div class='d-flex justify-content-between vps-action-header'>
    <button
      nbButton
      status="primary"
      class="suggestion-action-header-button"
      (click)="deleteSuggestions()"
      [disabled]="isDeleteButtonDisabled()"
    >
      Remove
    </button>
  </div>
  <table
    nbSort
    equalColumnsWidth
    (sort)='changeSort($event)'
    [nbTreeGrid]="(suggestions | paginate: pageSize:pageNumber | transformTableGrid | sortNbTable: sortRequest) || []"
  >

    <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef='allTableColumns'></tr>
    <tr nbTreeGridRow *nbTreeGridRowDef='let row; columns: allTableColumns' [clickToToggle]='false'></tr>

    <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.checkbox]" >
      <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef class="p-0 suggestion-table-peripheral">
        <div class="suggestion-table-checkbox">
          <nb-checkbox
              (change)="toggleAllSelection($event)"
              [checked]="isSelectedAll"
              class="suggestion-table-checkbox"
          />
        </div>
      </th>
      <td nbTreeGridCell *nbTreeGridCellDef="let row" class="p-0 suggestion-table-peripheral">
        <div class="suggestion-table-checkbox">
          <nb-checkbox type="checkbox" [checked]="isSelected(row.data)" (change)="toggleSelection(row.data)"/>
        </div>
      </td>
    </ng-container>

    <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.id]">
      <th
        nbTreeGridHeaderCell
        [nbSortHeader]="getDirection(tableColumnLabels.id)"
        *nbTreeGridHeaderCellDef
        class="suggestion-table-peripheral suggestion-table-nowrap"
      >
        {{ tableColumnLabels.id }}
      </th>
      <td nbTreeGridCell *nbTreeGridCellDef='let row' class="suggestion-table-nowrap">{{ row.data.id }}</td>
    </ng-container>

    <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.userEmail]">
      <th
        nbTreeGridHeaderCell
        [nbSortHeader]="getDirection(tableColumnLabels.userEmail)"
        *nbTreeGridHeaderCellDef
        class="suggestion-table-default"
      >
        {{ tableColumnLabels.userEmail }}
      </th>
      <td nbTreeGridCell *nbTreeGridCellDef='let row'>{{ row.data.userEmail }}</td>
    </ng-container>

    <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.suggestion]">
      <th
        nbTreeGridHeaderCell
        [nbSortHeader]="getDirection(tableColumnLabels.suggestion)"
        *nbTreeGridHeaderCellDef
        class="suggestion-table-suggestion"
      >
        {{ tableColumnLabels.suggestion }}
      </th>
      <td nbTreeGridCell *nbTreeGridCellDef='let row'>
        {{ row.data.suggestion }}
      </td>
    </ng-container>

  </table>

  <app-card-pagination
    [collectionSize]="suggestions.length"
    [LSKey]="LSPageSizeKey"
    (onPageChange)="onPageChange($event)"
  />
</app-card>

