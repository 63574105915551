<div class="playlist-card card">
  <div class="playlist-card-info d-flex justify-content-between align-items-center">
    <span *ngIf='playlist' class="unselectable">
      {{activePlaylistVideos()}} Videos
      <span *ngIf='activePlaylistVideos() > 0'>({{ playlist | playlistVideoLength }})</span>
    </span>
    <div class="playlist-card-info-right">
      <div *ngIf="isPlaylistUsing">
        <nb-tag
          appearance="filled"
          status="primary"
          class="unselectable"
          [text]="'Streaming'"
          [ngStyle]="{
        backgroundColor: 'var(--light-green)',
        border: 'none'
      }"
        />
      </div>
      <app-oriental-rectangle [isVertical]="isPlaylistAreVertical()" [size]="30"/>
    </div>
  </div>

  <div class="playlist-card-title">
    <h1 class="playlist-card-title-h1">{{ playlist.name }}</h1>
  </div>

  <div class="playlist-card-action">
    <button nbButton (click)="editCallback(playlist)">
      <nb-icon icon="edit-2-outline"/>
      Edit
    </button>
    <button nbButton (click)="openDetailsCallback(playlist)" *ngIf="isPlaylistUsing">
      <nb-icon icon="settings-outline"/>
      Details
    </button>
    <button nbButton status="danger" (click)="onRemove($event)" *ngIf="!isPlaylistUsing">
      <nb-icon icon="trash-outline"/>
      Remove
    </button>
  </div>

  <img
    [src]="isPlaylistNotEmpty() ? getFirstVideoThumbnail() : 'assets/DefaultStreamThumbnail.jpg'"
    class="background-image"
    alt="Video Thumbnail"
  >
</div>
