<div class="confirm-modal">
  <div style="overflow: hidden;">
    <p class="mb-3">{{textContent}}</p>
  </div>
</div>

<app-modal-action-buttons
  action-button-text="{{actionButtonText}}"
  action-button-theme="danger"
  [actionCallback]="submit.bind(this)"
  [cancelCallback]="closeModal.bind(this)"
></app-modal-action-buttons>
