<app-top-bar [page-title]="pageTitle"/>
<ng-container *ngIf="(pageState$ | async) as pageState" [ngSwitch]="pageState">
  <ng-container *ngSwitchCase="DataState.LOADING">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="DataState.LOADED">
    <app-card>
      <nb-card-header>
        <h5>Trigger Actions</h5>
      </nb-card-header>
      <nb-card-body>
        <div class="admin-setting-buttons">
          <button nbButton (click)="triggerWixCompare()">Trigger Wix Compare</button>
          <button nbButton (click)="triggerUpdateAllVps()">Trigger Update All Vps</button>
        </div>
      </nb-card-body>
    </app-card>
    <br>
    <app-card>
      <nb-card-header>
        <h5>General config</h5>
      </nb-card-header>
      <nb-card-body>
        <div class="admin-setting-actions" *ngIf="this.editableConfigs">
          <div *ngFor="let option of this.editableConfigs | keyvalue">

            <div *ngIf="option.value.type === 'time'">
              <nb-label class="admin-setting-action-label" [ngbTooltip]="option.value.tooltip">
                {{ option.value.label }}
              </nb-label>
              <app-timepicker (onChange)="onDateSelection($event, option)" [defaultValue]="option.value.value"/>
            </div>

            <div *ngIf="option.value.type === 'number'" class="admin-setting-action">
              <nb-label class="admin-setting-action-label" [ngbTooltip]="option.value.tooltip">
                {{ option.value.label }}
              </nb-label>
              <div>
                <input
                  nbInput
                  type="text"
                  [value]="option.value.value"
                  (input)="onInputChange($event, option)"
                  placeholder="e.g. 3.0"
                />
                <span *ngIf="option.value.measurement"> {{ option.value.measurement }}</span>
              </div>
            </div>
          </div>
        </div>
      </nb-card-body>
    </app-card>
  </ng-container>
  <!-- ERROR -->
  <ng-container *ngSwitchCase="DataState.ERROR">
    <div class="d-flex flex-column admin-setting-error">
      <img class="admin-setting-error-img" src="assets/sadcloud.png" alt="No characters">
      <p class="admin-setting-error-text">Failed</p>
    </div>
  </ng-container>

</ng-container>
