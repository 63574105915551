export enum VideoStatusCode {
  PENDING_UPLOADING=0,
  UPLOADING=1,
  PREPARING=2,
  READY_FOR_USE=10,
  ERROR_UPLOADING=20,
  ERROR_CODEC=21,
  ERROR_ACCESS=22,
  ERROR_SIZE=23,
}
