import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-error',
  templateUrl: `./input-error.component.html`,
  styleUrls: ['./input-error.component.scss']
})
export class InputErrorComponent {
  @Input("control") control: FormControl | any;
  @Input("label") label: String = "Option";

  constructor() {}
}
