import { Component, Input } from '@angular/core';
import { NbWindowRef, NbWindowService } from '@nebular/theme';
import { Observable, Subscription, of } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { User, Character, AiModel, buttonsConfig } from '../../models';
import { countTokens } from '../../utils/characterUtils';
import { CharacterOption, characterOption, CharacterOptions } from '../../consts/chatracter-options';
import { characterPresets, defaultCharacterPreset } from '../../consts/chatracter-presets';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-new-character-modal',
  templateUrl: './new-character-modal.component.html',
  styleUrls: ['./new-character-modal.component.scss']
})
export class NewCharacterModalComponent {
  @Input() user: User;
  @Input() character: Character;
  @Input() aiModels: AiModel[];
  onError: (message: string) => void;
  onSubmit: (...args: any) => void;

  characterForm: FormGroup;
  models$: Observable<string[]>;
  currentPreset: string = defaultCharacterPreset;
  tempPreset: string = defaultCharacterPreset;
  presets: string[] = [defaultCharacterPreset, ...characterPresets.map(({name}) => name)];
  isExpertMode: boolean = false;
  isLoading: boolean = false;
  value: number = 0;
  readonly options: CharacterOptions = characterOption;

  subscription: Subscription;
  tokenCounts = {
    name: 0,
    description: 0,
    personality: 0,
    firstMes: 0,
    questionAnswers: 0,
    model: 0,
  };
  deletedStop: boolean = false;

  constructor(
    private windowRef: NbWindowRef,
    private fb: FormBuilder,
    private windowService: NbWindowService,
  ) {
    this.characterForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(64)]],
      description: ['', [Validators.maxLength(1500), this.scenarioValidator]],
      personality: ['', [Validators.maxLength(1500), this.scenarioValidator]],
      firstMes: ['', [Validators.maxLength(200)]],
      questionAnswers: this.fb.array([]),
      stop: this.fb.array([]),
      model: ['', Validators.required],
      trimming_unfinished_sentences: [false],
      ...this.getCharacterFormSliderOptions(this.fb)
    });

    this.characterForm.get('name')?.valueChanges.subscribe(value => {
      this.tokenCounts.name = countTokens(value);
    });
    this.characterForm.get('description')?.valueChanges.subscribe(value => {
      this.tokenCounts.description = countTokens(value);
    });
    this.characterForm.get('personality')?.valueChanges.subscribe(value => {
      this.tokenCounts.personality = countTokens(!value ? value : value + "Personality: ");
    });
    this.characterForm.get('firstMes')?.valueChanges.subscribe(value => {
      this.tokenCounts.firstMes = countTokens(value);
    });
    this.characterForm.get('questionAnswers')?.valueChanges.subscribe(value => {
      const res = this.getFinalQuestionAnswers(value);
      this.tokenCounts.questionAnswers = countTokens(!res ? res : res + ". Example of messages: ");
    });
    this.characterForm.get('model')?.valueChanges.subscribe(value => {
      this.tokenCounts.model = value ? countTokens(value) : 0;
    });
  }

  scenarioValidator(control: AbstractControl): ValidationErrors | null {
    const scenarioCount = ((control.value || '').match(/{{scenario}}/g) || []).length;
    const description = control.parent?.get('description')?.value || '';
    const personality = control.parent?.get('personality')?.value || '';

    const scenariosCount = (description.match(/{{scenario}}/g) || []).length + (personality.match(/{{scenario}}/g) || []).length;
    if (scenarioCount > 1 || scenariosCount > 0 && scenariosCount > 1) {
      return {scenarioError: true};
    }
    return null;
  }

  getCharacterFormSliderOptions(fb: FormBuilder) {
    return Object.fromEntries(Object.entries(this.options)
      .filter(([key, { slider }]: any) => slider)
      .map(([key, { defaultValue, startValue, floor, cail }]: any) => {
        return [key, [startValue || defaultValue, [Validators.min(floor), Validators.max(cail)]]];
      })
    );
  }

  ngOnInit(): void {
    this.models$ = of(this.aiModels.map(({name}) => name));
    if (this.character) {
      this.setUpCharacterData(this.character);
    } else {
      (Array.isArray(this.options.stop.defaultValue) ? this.options.stop.defaultValue : [])
        .forEach((option) => this.addStop(option));
    }
  }

  setUpCharacterData(character: Character): void {
    const {
      aiModel,
      description,
      firstMes,
      id,
      mesExample,
      metadata = {},
      name,
      personality,
    } = character;

    let questionAnswers = mesExample ? this.getArrayOfQuestionAnswers(mesExample) : [];

    this.clearQuestionAnswer();
    questionAnswers.forEach(({ answer, question }: any) => this.addQuestionAnswer(answer, question));

    this.clearStop();
    (metadata?.['stop'] || this.options.stop.defaultValue)
      .forEach((stopWord: string | undefined) => this.addStop(stopWord === "\n" ? "\\n" : stopWord));


    const sliderValues = Object.fromEntries((Object.keys(this.options) as Array<keyof CharacterOptions>)
        .filter(key => this.options[key].slider)
        .map(key => ([ key, metadata[key] !== undefined
          ? metadata[key]
          : (this.options[key].defaultValue || this.options[key].defaultValue)
        ]))
    );
    this.isExpertMode = (Object.keys(this.options) as Array<keyof CharacterOptions>)
      .filter(key => this.options[key].expert)
      .some(key => this.options[key].defaultValue !== sliderValues[key])
      || JSON.stringify(metadata?.['stop']) !== JSON.stringify(this.options.stop.defaultValue)
      || metadata?.['trimming_unfinished_sentences']
      || false;
    this.characterForm.patchValue({
      name: name || '',
      description: description || '',
      personality: personality || '',
      id: id || '',
      metadata: metadata || {},
      firstMes: firstMes || '',
      model: aiModel,
      trimming_unfinished_sentences: metadata?.['trimming_unfinished_sentences'] || false,
      ...sliderValues,
    });

    setTimeout(() => this.resizeAllAreas(), 10);
  }

  getOptions(param: keyof CharacterOptions): CharacterOption {
    return this.options[param];
  }

  get questionAnswers() {
    return this.characterForm.get('questionAnswers') as FormArray;
  }

  get stopWords() {
    return this.characterForm.get('stop') as FormArray;
  }

  stopControls() {
    return this.stopWords.value;
  }

  addStop(initialValue: string = '') {
    this.stopWords.push(this.fb.control(initialValue, [Validators.maxLength(15)]));
  }

  clearStop() {
    this.stopWords.clear()
  }

  removeStop(index: number) {
    this.stopWords.removeAt(index);
    this.deletedStop = true;
    setTimeout(() => this.deletedStop = false, 50)
  }

  trackByStop(index: number, item: string): number | string {
    return this.deletedStop ? item : index;
  }

  addQuestionAnswer(answer?: string, question?: string) {
    this.questionAnswers.push(this.fb.group({
      question: [question || '', Validators.required],
      answer: [answer || '', Validators.required]
    }));
  }

  clearQuestionAnswer() {
    this.questionAnswers.clear();
  }

  removeQuestionAnswer(index: number) {
    this.questionAnswers.removeAt(index);
  }

  generateNewCharacter() {
    let {
      temperature,
      frequency_penalty,
      presence_penalty,
      repetition_penalty,
      top_k,
      top_p,
      min_p,
      top_a,
      questionAnswers,
      model,
      stop,
      ...character
    } = this.characterForm.value;

    const metadata = {
      ...Object.fromEntries(Object.entries(this.options)
        .filter(([key, option]: any) => (option.expert ? this.isExpertMode : true)
          && this.characterForm.value[key] !== option.defaultValue && !option.isNotSlider
        )
        .map(([key]) => [key, this.characterForm.value[key]])
      ),
      stop: stop ? this.generateStopArray(stop) : this.options.stop.defaultValue
    }

    const {
      filteredMetadata,
      ...oldCharacter
    } = this.character || {}

    return {
      ...oldCharacter,
      ...character,
      mesExample: this.getFinalQuestionAnswers(questionAnswers),
      aiModel: model,
      userId: this.user.id,
      metadata,
    };
  }

  submit() {
    this.characterForm.markAllAsTouched();
    if (this.characterForm.valid) {
      const character = this.generateNewCharacter();
      this.onSubmit(character);
      this.closeModal();
    } else {
      const { name, model } = this.characterForm.value;
      if (!name || !model) {
        this.onError('Type all required fields');
      } else {
        this.onError('Not all fields are correct');
      }
    }
  }

  getFinalQuestionAnswers(questionAnswers: any[]) {
    return questionAnswers.reduce((acc, e) => {
      acc += e.question.trim() + "\n" + e.answer.trim() + "\n\n";
      return acc;
    }, '');
  }

  getArrayOfQuestionAnswers(messages: string) {
    return messages.split('\n\n').map(message => {
      const [question, answer] = message.split('\n');
      return { question, answer };
    }).filter(e => e.question && e.answer);
  }

  generateStopArray(stop: string[]) {
    return [...new Set(stop)].filter(element => element).map(element => element == "\\n" ? "\n" : element);
  }

  replaceName(param: string) {
    return param.replaceAll('_', ' ').replace(/\b\w/g, c => c.toUpperCase());
  }

  closeModal() {
    this.windowRef.close();
  }

  toggleExpertMode() {
    this.isExpertMode = !this.isExpertMode;
  }

  getExpertPramsLabels() {
    return Object.entries(this.options).filter(([key, option]: any) => option.expert).map(([key]: any) => key)
  }

  resizeAllAreas() {
    this.resizeTextareaByName("answer");
    this.resizeTextareaByName("personality");
    this.resizeTextareaByName("description");
  }

  resizeTextareaByName(name: string) {
    const textarea = document.querySelectorAll(`textarea[formControlName="${name}"]`);
    textarea.forEach(e => {
      this.autoResize({ target: e });
    });
  }


  autoResize(event: any) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight + 5 }px`;
  }

  onChangePreset(presetName: string) {
    if (!this.currentPreset || this.currentPreset === presetName) {
      return;
    }
    this.openPresetConfirmModal(presetName);
  }

  resetDefault() {
    this.clearStop();
    Array.isArray(this.options.stop.defaultValue) && this.options.stop.defaultValue
      .forEach((stopWord: string | undefined) => this.addStop(stopWord === "\n" ? "\\n" : stopWord));

    const sliderValues = Object.fromEntries((Object.keys(this.options) as Array<keyof CharacterOptions>)
      .filter(key => this.options[key].slider)
      .map(key => ([ key, this.options[key].startValue || this.options[key].defaultValue]))
    );
    this.characterForm.patchValue({
      ...sliderValues,
      trimming_unfinished_sentences: this.options["trimming_unfinished_sentences"].defaultValue,
    });
  }

  openPresetConfirmModal(presetName: string) {
    this.windowService.open(ConfirmModalComponent, {
      title: `Are you sure you want change preset?`,
      buttons: buttonsConfig,
      context: {
        textContent: `This action will permanently change all fields`,
        actionButtonText: "Set Preset",
        actionCallback: () => this.setPreset(presetName),
        cancelCallback: () => {this.tempPreset = this.currentPreset}
      },
      windowClass: "confirm-modal"
    });
  }

  setPreset(presetName: string) {
    this.currentPreset = presetName;
    this.tempPreset = presetName;
    let character: Character = { aiModel: '', id: '', name: '', userId: 0 };
    if (this.currentPreset === defaultCharacterPreset) {
      this.setUpCharacterData(character);
    } else {
      this.setUpCharacterData(characterPresets.find(preset => preset.name === presetName)|| character);
    }
  }
}
