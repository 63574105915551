import { Component, OnInit } from '@angular/core';
import { NbToastrService, NbWindowService } from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { map, Observable, take, zip } from 'rxjs';
import { PlaylistService } from '../../services/playlist.service';
import { PlaylistActions } from '../../store/actions/playlist.actions';
import { playlistFeature } from '../../store/reducers/playlist.reducers';
import { VideoUploadActions } from '../../store/actions/videos.actions';
import { NewStreamModalComponent } from '../../components/new-stream-modal/new-stream-modal.component';
import { StreamService } from '../../services/stream.service';
import { AxiosResponse } from 'axios';
import { StreamActions } from '../../store/actions/stream.actions';
import { streamFeature } from '../../store/reducers/stream.reducers';
import { User, buttonsConfig, Stream, Playlist, Character } from 'models';
import { userFeature } from '../../store/reducers/user.reducers';
import { showError, showWarning } from 'helpers/nb';
import { UserActions } from '../../store/actions/user.actions';
import { UserService } from '../../services/user.service';
import { StreamPlatform } from '../../enum/streams.enum';
import { Actions, ofType } from '@ngrx/effects';
import { WarningModalComponent } from '../../components/warning-modal/warning-modal.component';
import { AuthorizationCodeComponent } from '../../components/authorization-code/authorization-code.component';
import { VideoSource, VideoSourceToShow } from '../../enum/upload-source.enum';
import { environment } from '../../../environments/environment';
import { ChatModalComponent } from '../../components/chat-modal/chat-modal.component';
import { CharacterActions } from '../../store/actions/characters.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss']
})
export class StreamComponent implements OnInit{
  pageTitle: string = 'My Streams';
  playlists$: Observable<Playlist[]>;
  streams$: Observable<Stream[]>;
  getStreamCardPlaceholders$: Observable<any[]>;
  streams: Stream[];
  user: User;
  dialogActive = true;
  isChatBotAllowed = false;

  constructor(
    private windowService: NbWindowService,
    private store: Store,
    private playlistService: PlaylistService,
    private streamService: StreamService,
    private toastrService: NbToastrService,
    private userService: UserService,
    private actions$: Actions,
    private router: Router,
  ) {
    this.playlists$ = this.store.pipe(select(playlistFeature.selectPlaylistState));
    this.streams$ = this.store.pipe(select(streamFeature.selectStreamState))
      .pipe(map(data => [...data].sort((a: any, b: any) =>  a.id - b.id)));
    this.streams$.subscribe((data) => this.streams = data);
    this.store
      .pipe(
        select(userFeature.selectUserState),
        take(1)
      )
      .subscribe((user) => {
        this.user = user as User;
        const supportModeEnabled = sessionStorage.getItem('supportMode');
        if (this.user && !this.user.isVpsAssigned && this.user.isAdmin && !supportModeEnabled) {
          this.router.navigate(['/dashboard']);
        } else {
          this.store.dispatch(CharacterActions.getCharactersList({ userUuid: this.user.uuid }));
          this.isChatBotAllowed = !!this.user?.subscriptionPlan?.chatbot;
        }
    });
    this.getStreamCardPlaceholders$ = this.streams$.pipe(
      map(streams => {
        return [streams, this.user?.subscriptionPlan?.availableStreams || 0]
      }),
      map(([streams, availableStreams]) => {
        if (this?.streams?.length >= 4) {
          return [];
        }
        const placeholdersLength = 4 - this.streams.length
        let placeholdersCards = new Array(placeholdersLength)
          .fill(0)
          .map((_, index) => index + this.streams.length + 1 <= availableStreams
            ? {allowAddStream: true}
            : {allowAddStream: false}
          )
        return placeholdersCards;
      })
    )
  }

  ngOnInit() {
    this.playlistService.getAll().then(({data}) => {
      this.store.dispatch(PlaylistActions.fetchPlaylists({playlists: data}))
    })
    this.streamService.fetchStreams().then(({data}: AxiosResponse<Stream[]>) => {
      this.store.dispatch(StreamActions.getStreams({streams: data}))
    })
  }

  openNewStreamModal() {
    zip(
      this.streams$.pipe(take(1)),
      this.playlists$.pipe(take(1))
    ).subscribe(([streams, playlists]) => {
      this.windowService.open(NewStreamModalComponent, {
        title: `Configure Stream`,
        buttons: buttonsConfig,
        context: {
          editableStream: {},
          streams,
          playlists,
          user: this.user,
          testCharacter: (stream: Stream) => this.testCharacter(stream),
          onError: showWarning.bind(this, this.toastrService),
         }
      });
    })
  }

  openEditStream(stream: Stream) {
    zip(
      this.streams$.pipe(take(1)),
      this.playlists$.pipe(take(1))
    ).subscribe(([streams, playlists]) => {
      const editableStream: Stream =  {...stream, playlistId: stream.playlist?.id, platformName: stream.platformName};
      this.windowService.open(NewStreamModalComponent, {
        title: `Configure Stream`,
        buttons: buttonsConfig,
        context: {
          editableStream,
          streams,
          playlists,
          user: this.user,
          testCharacter: (stream: Stream) => this.testCharacter(stream),
          onError: showWarning.bind(this, this.toastrService),
         }
      });
    })
  }
  openDetailsStream(stream: Stream) {
    zip(
      this.streams$.pipe(take(1)),
      this.playlists$.pipe(take(1))
    ).subscribe(([streams, playlists]) => {
      const editableStream: Stream =  { ...stream, playlistId: stream.playlist?.id };
      this.windowService.open(NewStreamModalComponent, {
        title: `Stream Details`,
        buttons: buttonsConfig,
        context: {
          editableStream,
          streams,
          playlists,
          user: this.user,
          testCharacter: (stream: Stream) => this.testCharacter(stream),
          onError: showWarning.bind(this, this.toastrService),
          viewOnly: true,
         }
      });
    })
  }

  startStream(streamId: number) {
    let streamToStart: Stream;
    this.streams$.pipe(
      take(1),
      map(data => {
        let stream = data.find(({id}) => id === streamId);
        if (!stream) {
          return false;
        } else if (stream.platformName === StreamPlatform.YouTube || stream.platformName === StreamPlatform.Twitch) {
          streamToStart = stream;
          return !!stream?.character;
        }
        return false;
      }),
    ).subscribe(isCheckAccessRequired => {
      const platform = streamToStart && streamToStart.platformName;
      if (isCheckAccessRequired && (platform === StreamPlatform.YouTube || platform === StreamPlatform.Twitch)) {
        this.getServiceAuthorizationRequired(streamId, platform);
      } else {
        this.startStreamById(streamId);
      }
    });
  }

  startStreamById(streamId: number) {
    this.streamService.startStreams(streamId).then(({data}) => {
      if (data) {
        this.store.dispatch(StreamActions.updateStream({stream: data}));
      }
    })
  }

  getServiceAuthorizationRequired(streamId: number, platform: number) {
    let name = "";
    let platformName = "";
    if (platform  === StreamPlatform.Twitch) {
      name = VideoSourceToShow.TWITCH;
      platformName = VideoSource.TWITCH;
    } else {
      name = VideoSourceToShow.YOUTUBE;
      platformName = VideoSource.YOUTUBE;
    }
    this.store.dispatch(VideoUploadActions.checkServiceAccess({ platformName }));
    this.actions$.pipe(
      ofType(VideoUploadActions.authorizationRequired),
      take(1)
    )
      .subscribe(({ authCode, error }) => {
        if (error) {
          console.error(error);
          showError(this.toastrService, "Failed to check access. Error: " + (typeof error === 'string' ? error : error.message));
          return;
        }
        if (authCode) {
          showWarning(this.toastrService, `${name} access required. Please allow permissions and try again.`)
          this.windowService.open(WarningModalComponent, {
            title: `${name} Access Required`,
            buttons: buttonsConfig,
            context: {
              actionButtonText: "retry",
              customComponent: AuthorizationCodeComponent,
              actionCallback: () => this.getServiceAuthorizationRequired(streamId, platform),
              customComponentParams: {
                platform,
              }
            },
          });
        } else {
          this.startStreamById(streamId);
        }
      });
  }

  stopStream(streamId: number) {
    this.streamService.stopStreams(streamId).then(({data}) => {
      if (data) {
        this.store.dispatch(StreamActions.updateStream({stream: data}))
      }
    })
  }

  isUpgradeModalVisible() {
    if (this.user.subscriptionPlan?.availableStreams && this.user.subscriptionPlan?.availableStreams < 4) {
      return false;
    }
    const millisecondsStr = localStorage.getItem('upgrade_dialog_closed');
    if (millisecondsStr != null) {
      const milliseconds = Number.parseInt(millisecondsStr);
      const month = 1000 * 60 * 60 * 24 * 30;
      if ((milliseconds + month) < Date.now()) {
        return true;
      }
    }
    return false;
  }

  openUpgradePlan() {
    window.open(`${environment.homePage}/membership`, '_blank');
  }

  temporaryCloseUpgradeDialog() {
    localStorage.setItem("upgrade_dialog_closed", Date.now().toString())
    this.dialogActive = false;
  }

  testCharacter(stream: Stream) {
    this.windowService.open(ChatModalComponent, {
      title: `Test character chat`,
      buttons: buttonsConfig,
      closeOnEsc: false,
      context: {
        user: this.user,
        character: stream.character,
        stream: stream,
        onSubmit: () => {},
        onError: showWarning.bind(this, this.toastrService),
      },
      windowClass: "scroll-modal"
    });
  }
}
