import { Injectable } from '@angular/core';
import axios, { AxiosResponse } from 'axios';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Suggestion } from '../models/suggestion';


@Injectable({
  providedIn: 'root'
})
export class SuggestionService {
  private readonly apiUrl = environment.apiUrl + '/suggestion';

  constructor(private http: HttpClient) {
  }

  getAll(): Promise<AxiosResponse<Suggestion[]>> {
    return axios.get(this.apiUrl)
  }

  getAll$(): Observable<Suggestion[]> {
    return this.http.get<Suggestion[]>(this.apiUrl)
  }

  giveSuggestion(suggestion: String): Promise<AxiosResponse<Suggestion[]>> {
    return axios.post(this.apiUrl, {suggestion});
  }

  deleteSuggestions(ids: number[]): Promise<AxiosResponse<any>> {
    return axios.delete(`${this.apiUrl}/`, { data: { ids } });
  }
}
