<app-top-bar [page-title]="pageTitle"/>

<ng-container *ngIf="(pageState$ | async) as pageState" [ngSwitch]="pageState">
  <!-- SPINNER -->
  <ng-container *ngSwitchCase="DataState.LOADING">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </ng-container>
  <!-- CARDS -->
  <ng-container *ngSwitchCase="DataState.LOADED">
    <div class="dashboard-page d-flex flex-wrap ">
        <ng-container *ngFor="let card of dashboardSummary$ | async">
          <div class="dashboard-info-card">
            <app-dashboard-card
              *ngIf="cardTitleMap[card.relate] && (cardTitleMap[card.relate][card.property]) as title"
              [title]="title"
              [summary]="card"
              (onConfigChange)="changeConfig($event)"
            ></app-dashboard-card>
          </div>
        </ng-container>
    </div>
  </ng-container>
  <!-- ERROR -->
  <ng-container *ngSwitchCase="DataState.ERROR">
    <div class="d-flex flex-column dashboard-error">
      <img class="dashboard-error-img" src="assets/sadcloud.png" alt="No videos">
      <p class="dashboard-error-text">Failed to load dashboard, please try again</p>
    </div>
  </ng-container>
</ng-container>
