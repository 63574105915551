<div class="stream-modal-container">
  <div class="stream-modal-field mb-3">
    <label>Streaming Service</label>
    <nb-select
      placeholder="Select Streaming Service" class="full-width"
      [(selected)]="editableStream.platformName"
      (selectedChange)="onPlatformChange($event)"
      [compareWith]="selectComparator"
      [disabled]="viewOnly"
    >
      <nb-option *ngFor="let streamPlatform of allowedStreamPlatforms, let i = index" [value]="i">{{ streamPlatform }}</nb-option>
    </nb-select>
    <span style="font-size: 12px" *ngIf="editableStream.platformName == StreamPlatform.Facebook">
      Please note that Facebook has 8 hours limitation of stream duration.
    </span>
  </div>
  <div
    *ngIf="editableStream.platformName == StreamPlatform.Custom"
    class="stream-modal-field"
    [@fadeInOut]
  >
    <label>Streaming URL</label>
    <input
      nbInput
      type="text"
      placeholder="rtmp://yourserver.com"
      class="input-full-width"
      fieldSize="medium"
      [value]="editableStream.streamUrl || ''"
      [disabled]="!(editableStream.platformName == StreamPlatform.Custom) || viewOnly"
      (change)="updateField($event.target, 'streamUrl')"
    >
  </div>
  <div class="stream-modal-field">
    <label>Streaming Key (<a
        href="{{ environment.homePage }}/post/how-to-retrieve-your-streaming-keys-a-step-by-step-guide-for-youtube-twitch-and-facebook"
        target="_blank"
        style="font-size: 11px">How to find the streaming keys?</a>)</label>
    <nb-form-field style="align-items: flex-start;">
      <input
        nbInput
        fieldSize="large"
        class="input-full-width"
        placeholder="Enter Streaming Key"
        [type]="showStreamingKey ? 'text' : 'password'"
        [value]="editableStream.streamKey || ''"
        (change)="updateField($event.target, 'streamKey')"
        [disabled]="viewOnly"
      >
      <button nbSuffix nbButton ghost (click)="showStreamingKey = !showStreamingKey">
        <nb-icon [icon]="showStreamingKey ? 'eye-outline' : 'eye-off-outline'">
        </nb-icon>
      </button>
    </nb-form-field>
  </div>
  <div class="stream-modal-field mb-3">
    <label>Select Playlist</label>
    <nb-select
      fullWidth
      [placeholder]="playlists.length ? 'Select playlist' : 'You haven\'t created any playlists'"
      [compareWith]="selectComparator"
      [(selected)]="editableStream.playlistId"
      [disabled]="viewOnly"
    >
      <nb-option *ngFor="let playlist of playlists" value="{{playlist.id}}">
        {{playlist.name}} ({{playlist | playlistVideoLength}}) {{ isVertical(playlist) }}
      </nb-option>
    </nb-select>
  </div>

  <div class="stream-modal-field mb-3" *ngIf="isUserInAllowedToChatbot()">
    <label>Character</label>

    <button
      *ngIf="!editableStream.character"
      nbButton
      status="primary"
      class="stream-modal-character-actions-button"
      (click)="editCharacter()"
      [ngStyle]="{'backgroundColor': 'var(--button-dark)'}"
      [disabled]="viewOnly"
    >
      Add Character
    </button>
    <div class="stream-modal-character">
      <div *ngIf="getCharacterName()" class="stream-modal-character-name">
        {{ getCharacterName() }}
      </div>
      <div class="stream-modal-character-actions">
        <button
          *ngIf="!!editableStream.character"
          nbButton
          status="primary"
          class="stream-modal-character-actions-button"
          (click)="editCharacter()"
          [ngStyle]="{'backgroundColor': 'var(--button-dark)'}"
          [disabled]="viewOnly"
        >
          Edit
        </button>
        <button
          *ngIf="!!editableStream.character"
          nbButton
          status="primary"
          class="stream-modal-character-actions-button"
          (click)="testCharacter(editableStream)"
          [ngStyle]="{'backgroundColor': 'var(--button-dark)'}"
          [disabled]="viewOnly"
        >
          Test
        </button>
        <button
          *ngIf="!!editableStream.character"
          nbButton
          status="primary"
          class="stream-modal-character-actions-button"
          (click)="unassignCharacter()"
          [ngStyle]="{'backgroundColor': 'var(--button-dark)'}"
          [disabled]="viewOnly"
        >
          Unassign
        </button>
      </div>
    </div>
  </div>
</div>

<app-modal-action-buttons
  [actionCallback]="submit.bind(this)"
  [actionButtonDisabled]="editableStream.platformName === undefined || editableStream.platformName === null || viewOnly"
  [cancelCallback]="closeModal.bind(this)"
>
</app-modal-action-buttons>
