<div class="top-bar">

    <div class="top-bar-describe">
      <h1 class="top-bar-describe-title">{{ title }}</h1>
      <div class="top-bar-describe-labels" *ngIf="!isLoading">
        <app-label
          *ngFor="let label of transformed_labels; let i = index"
          [text]="label"
          [color-schema]="i"
        />
      </div>
      <div class="top-bar-describe-labels" *ngIf="isLoading">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <ng-container *ngIf="(user$ | async) as user">
      <div *ngIf="user.email" class="top-bar-profile">
        <span class="top-bar-profile-email">{{ user.email }}</span>
        <nb-user
          size="medium"
          name="{{ user.name }}"
          onlyPicture
          color='#ff000017'
        >
        </nb-user>
      </div>
  </ng-container>

</div>

