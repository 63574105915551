<div class="playlist-creation-video">
  <nb-card class="playlist-creation-video-card">
    <nb-card-body class="playlist-creation-video-content">
      <table
        nbSort
        class="playlist-creation-video-table"
        (sort)="updateSort($event)"
        [nbTreeGrid]="(resources | paginate: pageSize:pageNumber | transformTableGrid | sortNbTable: sortRequest ) || []"
      >
        <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allTableColumns"></tr>
        <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allTableColumns" [clickToToggle]="false"></tr>

        <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.checkbox]">
          <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef class="playlist-creation-video-table-peripheral p-0">
            <div class="playlist-creation-video-table-checkbox">
              <nb-checkbox (change)="toggleAllSelection($event)" [checked]="isSelectedAll" class="playlist-creation-video-table-checkbox"/>
            </div>
          </th>
          <td nbTreeGridCell *nbTreeGridCellDef="let row" class="playlist-creation-video-table-peripheral p-0">
            <div class="playlist-creation-video-table-checkbox">
              <nb-checkbox type="checkbox" [checked]="isSelected(row.data)" (change)="toggleSelection(row.data)"/>
            </div>
          </td>
        </ng-container>

        <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.series]">
          <th
            class="playlist-creation-video-table-peripheral p-0"
            nbTreeGridHeaderCell
            *nbTreeGridHeaderCellDef
          ></th>
          <td
            class="playlist-creation-video-table-peripheral p-0"
            nbTreeGridCell
            *nbTreeGridCellDef="let i = index"
          >
            {{ (pageSize * (pageNumber - 1)) + (i + 1) }}
          </td>
        </ng-container>

        <ng-container [nbTreeGridColumnDef]="tableColumnNames[tableColumnLabels.name]">
          <th
            style="width: 40%;"
            nbTreeGridHeaderCell
            [nbSortHeader]="getSortDirection(tableColumnLabels.name)"
            *nbTreeGridHeaderCellDef
          >
            {{ tableColumnLabels.name }}
          </th>
          <td
            nbTreeGridCell
            *nbTreeGridCellDef="let row"
          >
            <div class="table-thumbnail" [ngClass]="{'table-thumbnail-vertical': row.data.isVerticalOrientation}">
              <img [id]="row?.data?.id" [src]="row.data.thumbnail || 'assets/DefaultVideoThumbnail.jpg'" alt="Thumbnail">
            </div>
            <span class="playlist-creation-video-table-video-name">{{ row.data.name }}</span>
          </td>
        </ng-container>
      </table>
    </nb-card-body>
  </nb-card>
  <ng-container *ngIf="resources as resources">
    <app-card-pagination
      [collectionSize]="resources.length"
      [LSKey]="LSPageSizeKey"
      (onPageChange)="onPageChange($event)"
    />
  </ng-container>
</div>
