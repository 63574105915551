import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getAuthorizationCode } from '../../store/reducers/videos.reducers';
import { StreamPlatform } from '../../enum/streams.enum';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-authorization-code',
  templateUrl: `./authorization-code.component.html`,
  styleUrls: ['./authorization-code.component.scss']
})
export class AuthorizationCodeComponent {
  @Input() platform: number = 1;
  @Input() authCode: string;
  authorizationCode$: Observable<string | null | boolean>;
  readonly StreamPlatform = StreamPlatform;

  constructor(private store: Store) {
    this.authorizationCode$ = this.store.select(getAuthorizationCode);
  }

  protected readonly environment = environment;
}
