<div class="vps-modal-container">
  <nb-radio-group [(ngModel)]="option">
    <nb-radio value="all">
      All Users
    </nb-radio>
    <nb-radio value="byEmail">
      By Email
    </nb-radio>
  </nb-radio-group>
  <div class="vps-modal-field">
    <label>Email to compare</label>
    <nb-form-field style="align-items: flex-start;">
      <input
        nbInput
        fieldSize="large"
        class="input-full-width"
        [(ngModel)]="email"
        placeholder="Enter Email to compare"
        autocomplete="no"
        [disabled]="isEmailInputDisabledDisabled()"
      >
    </nb-form-field>
  </div>

</div>

<app-modal-action-buttons
  [actionCallback]="submitForm.bind(this)"
  [actionButtonDisabled]="isButtonDisabled()"
  [cancelCallback]="closeModal.bind(this)"
>
</app-modal-action-buttons>
