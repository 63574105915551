import { NbToastrService } from "@nebular/theme";

export function showWarning(toastrService: NbToastrService, message: string, duration?: number) {
  toastrService.show(message, `Warning`, {
    status: 'warning',
    duration: duration || 10000,
    preventDuplicates: true,
  });
}

export function showSuccess(toastrService: NbToastrService, message: string, duration?: number) {
  toastrService.show(message, `Success`, {
    status: 'success',
    duration: duration || 5000,
  });
}

export function showError(toastrService: NbToastrService, message: string, duration?: number) {
  toastrService.show(message, `Error`, {
    status: 'danger',
    duration: duration || 5000,
  });
}
