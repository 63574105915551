<div style='width: 244px; background: #FFFFFF; height: 100%' class='d-flex flex-column'>

  <div class='mb-4 me-4 ms-4 logo-container' style="cursor: pointer" (click)="openLandingApp()">
    <img src='assets/logo.svg'>
  </div>

  <div class='d-flex flex-column justify-content-between h-100'>
    <div>
      <app-sidebar-button *ngIf='user.isVpsAssigned' title='My Videos' icon='ph-user' link='/videos'/>
      <app-sidebar-button *ngIf='user.isVpsAssigned' title='My Playlists' icon='ph-monitor-play' link='/playlists'/>
      <app-sidebar-button *ngIf='user.isVpsAssigned' title='My Streams' icon='ph-screencast' link='/streams'/>
      <app-sidebar-button *ngIf='user.isVpsAssigned && isAllowedToUseChatbot()' title="My Characters" icon='ph-user-plus' link='/character'/>
      <app-sidebar-button *ngIf='user.isAdmin' title='Admin Settings' icon='ph-gear' link='/admin-settings'/>
      <app-sidebar-button *ngIf='user.isAdmin' title='Admin Dashboard' icon='ph-squares-four' link='/dashboard'/>
      <app-sidebar-button *ngIf='user.isAdmin' title='VPSs Management' icon='ph-rows' link='/vps-management'/>
      <app-sidebar-button *ngIf='user.isAdmin' title='Suggestions from Customers' icon='ph-notepad' link='/suggestions'/>
    </div>

    <div>
      <app-sidebar-button title='Give Suggestions!' icon='ph-lightbulb-filament' (click)="openSuggestionModal()"/>
      <app-sidebar-button title='Contact Us' icon='ph-address-book' (click)="openContactForm()"/>
      <app-sidebar-button title='Home Page' icon='ph-sign-out' (click)='logout()'/>
    </div>
  </div>
</div>
