export interface CharacterOption {
  floor?: number;
  ceil?: number;
  step?: number;
  defaultValue: number | string[] | boolean;
  startValue?: number | string[] | boolean;
  expert?: boolean;
  tooltip: string;
  slider?: boolean;
  inputs?: boolean;
  checkbox?: boolean;
  mapping?: (value: any) => any;
}

export interface CharacterOptions {
  temperature: CharacterOption;
  frequency_penalty: CharacterOption;
  presence_penalty: CharacterOption;
  repetition_penalty: CharacterOption;
  top_k: CharacterOption;
  top_p: CharacterOption;
  min_p: CharacterOption;
  top_a: CharacterOption;
  stop: CharacterOption;
  trimming_unfinished_sentences: CharacterOption;
}

export const characterOption: CharacterOptions = {
  temperature: {
    slider: true,
    floor: 0.0,
    ceil: 2.0,
    step: 0.01,
    defaultValue: 1.0,
    startValue: 1.1,
    tooltip: '- Controls randomness and creativity.\n' +
      '- Higher values (closer to 1) => more diverse, sometimes unpredictable output.\n' +
      '- Lower values (toward 0) => more focused, deterministic output.'
  },
  trimming_unfinished_sentences: {
    checkbox: true,
    defaultValue: false,
    expert: true,
    startValue: false,
    mapping: (stop) => stop ? stop.map((element: string) => element == "\n" ? "\\n" : element).join(" "): ["***", "{{user}}"],
    tooltip: '- When enabled, this feature should remove unfinished sentences.\n' +
      '- An unfinished sentence is one that does not end with (., !, ?) or an emoji.'
  },
  stop: {
    defaultValue: ["***", "{{user}}"],
    expert: true,
    inputs: true,
    startValue: ["***", "{{user}}"],
    mapping: (stop) => stop ? stop.map((element: string) => element == "\n" ? "\\n" : element).join(" "): ["***", "{{user}}"],
    tooltip: '- Specifies a list of tokens that will halt text generation if encountered.\n' +
      '- The model will stop generating output immediately when it hits any of the tokens in this list.\n' +
      '- Commonly used to control where and when the generated content should end.\n' +
      '- Useful for specifying end markers, sentence boundaries, or other stopping criteria.'
  },
  frequency_penalty: {
    slider: true,
    floor: -2,
    ceil: 2,
    step: 0.01,
    defaultValue: 0.0,
    expert: true,
    tooltip: '- Discourages repeating words/tokens used frequently so far.\n' +
      '- Higher penalty => less repetition of already-used tokens/phrases.'
  },
  presence_penalty: {
    slider: true,
    floor: -2,
    ceil: 2,
    step: 0.01,
    defaultValue: 0.0,
    expert: true,
    tooltip: '- Encourages introducing new topics/phrases.\n' +
      '- Higher penalty => more novel or varied content in the text.'
  },
  repetition_penalty: {
    slider: true,
    floor: 0,
    ceil: 2,
    step: 0.01,
    defaultValue: 1.0,
    startValue: 1.03,
    expert: true,
    tooltip: '- Applies an additional penalty for repeated tokens to reduce looping or echoing.\n' +
      '- A value slightly above 1 can help avoid repeated phrases or spam.'
  },
  top_k: {
    slider: true,
    floor: 0,
    ceil: 100,
    defaultValue: 0,
    expert: true,
    tooltip: '- Restricts the model to the K most likely tokens at each step.\n' +
      '- Lower K => more deterministic (fewer possible next tokens).\n' +
      '- Higher K => broader pool of possible next words.'
  },
  top_p: {
    slider: true,
    floor: 0,
    ceil: 1,
    step: 0.01,
    defaultValue: 1.0,
    expert: true,
    tooltip: '- Model will consider tokens until their cumulative probability reaches P.\n' +
      '- Lower P => safer, more likely tokens.\n' +
      '- Higher P => more risk-taking, varied tokens.'
  },
  min_p: {
    slider: true,
    floor: 0,
    ceil: 1,
    step: 0.01,
    defaultValue: 0.0,
    startValue: 0.08,
    expert: true,
    tooltip: '- Sets a minimum probability threshold.\n' +
      '- Tokens below this threshold are filtered out (implementation-specific).\n' +
      '- Helps remove extremely unlikely or “nonsense” tokens.'
  },
  top_a: {
    slider: true,
    floor: 0,
    ceil: 1,
    step: 0.01,
    defaultValue: 0.0,
    expert: true,
    tooltip: '- Alpha sorting factor used in some advanced sampling methods.\n' +
      '- Often left at 0 if you’re not explicitly tweaking token-sorting algorithms.\n' +
      '- Higher values can alter how token probabilities are prioritized or “re-ranked.”'
  },
}
