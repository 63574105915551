import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chat-rectangle',
  templateUrl: './chat-rectangle.component.html',
  styleUrls: ['./chat-rectangle.component.scss']
})
export class ChatRectangleComponent {
  @Input() isVertical: boolean = false;
  @Input() size: number = 200;
}
